import { takeLatest, call, put } from 'redux-saga/effects';
import { getToken } from 'libs/token';
import { getTimeSheets } from 'api/methods/timesheets';
import { GETTIMESHEETS } from './actionTypes';
import { getTimeSheetsSuccess, getTimeSheetsFail } from './reducer';

function* get_time_sheets(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getTimeSheets, action.payload, userToken);
  if (response.status === 200) {
    yield put(getTimeSheetsSuccess(response.data));
  } else {
    yield put(getTimeSheetsFail(response.data));
  }
}

export const saga = function* () {
  yield takeLatest(GETTIMESHEETS, get_time_sheets);
};
