import apiCall from 'libs/apiCall';

export const getNotificationList = token =>
  apiCall(`/notification/notifications/?page_size=20`, null, 'GET', token);

export const markAllReadNotificaions = token =>
  apiCall(`/notification/notifications/read/`, null, 'PUT', token);

export const markOneReadNotificaion = (notification_id, token) =>
  apiCall(
    `/notification/notifications/read/${notification_id}/`,
    null,
    'PUT',
    token
  );
