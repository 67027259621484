import { handleActions, createAction } from 'redux-actions';
import { defineLoopActions, requestLoopHandlers } from 'libs/state';
import { REQUEST_STATUS } from 'config/constants';

import {
  OPENVIDEOCHAT,
  UPDATEPARTICIPANTCONNECTIONEVENT,
  CREATENOTEBYTYPE,
  GETNOTESLIST,
  UPDATENOTEBYTYPE,
  UPDATEVIDEOCALLMODE,
  COMPLETEAPPOINTMENT,
  COMPLETEAPPOINTMENTCLICKED,
  COMPLETEAPPOINTMENTFINALISE,
  UPDATEPATIENTMETRICS,
  CREATEAPPOINTMENTROOM,
} from './actionTypes';

const initialState = {
  create_note_payload: {},
  notes_list: [],
  completeAppointment: null,
  participantConnectionEvent: {},
  videoCallMode: 'mid',
  openVideoChat: false,
  completeAppointmentClicked: false,
  completeAppointmentFinalise: false,
  create_note_state: REQUEST_STATUS.INITIAL,
  get_notes_state: REQUEST_STATUS.INITIAL,
  update_note_state: REQUEST_STATUS.INITIAL,
  get_completeappointment_state: REQUEST_STATUS.INITIAL,
  update_patient_metrics_state: REQUEST_STATUS.INITIAL,
  create_appointment_room_state: REQUEST_STATUS.INITIAL,
};

export const {
  start: createNote,
  success: createNoteSuccess,
  fail: createNoteFail,
} = defineLoopActions(CREATENOTEBYTYPE);

export const {
  start: getNotesList,
  success: getNotesListSuccess,
  fail: getNotesListFail,
} = defineLoopActions(GETNOTESLIST);
export const {
  start: completeAppointment,
  success: completeAppointmentSuccess,
  fail: completeAppointmentFail,
  reset: completeAppointmentReset,
} = defineLoopActions(COMPLETEAPPOINTMENT);

export const {
  start: updateNote,
  success: updateNoteSuccess,
  fail: updateNoteFail,
} = defineLoopActions(UPDATENOTEBYTYPE);

export const {
  start: updatePatientMetrics,
  success: updatePatientMetricsSuccess,
  fail: updatePatientMetricsFail,
} = defineLoopActions(UPDATEPATIENTMETRICS);

export const {
  start: createAppointmentRoom,
  success: createAppointmentRoomSuccess,
  fail: createAppointmentRoomFail,
} = defineLoopActions(CREATEAPPOINTMENTROOM);

export const updateParticipantConnectionEvent = createAction(
  UPDATEPARTICIPANTCONNECTIONEVENT
);
export const openVideoChat = createAction(OPENVIDEOCHAT);
export const updateVideoCallMode = createAction(UPDATEVIDEOCALLMODE);
export const completeAppointmentClicked = createAction(
  COMPLETEAPPOINTMENTCLICKED
);
export const completeAppointmentFinalise = createAction(
  COMPLETEAPPOINTMENTFINALISE
);

export const liveSessionReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: COMPLETEAPPOINTMENT,
      onSuccess: (state, payload) => {
        return {
          ...state,
          completeAppointment: payload,
          get_completeappointment_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_completeappointment_state',
    }),
    [UPDATEPARTICIPANTCONNECTIONEVENT]: (state, { payload }) => {
      return {
        ...state,
        participantConnectionEvent: payload,
      };
    },
    [UPDATEVIDEOCALLMODE]: (state, { payload }) => {
      return {
        ...state,
        videoCallMode: payload,
      };
    },
    [OPENVIDEOCHAT]: (state, { payload }) => {
      return {
        ...state,
        openVideoChat: payload,
      };
    },

    [COMPLETEAPPOINTMENTCLICKED]: (state, { payload }) => {
      return {
        ...state,
        completeAppointmentClicked: payload,
      };
    },

    [COMPLETEAPPOINTMENTFINALISE]: (state, { payload }) => {
      return {
        ...state,
        completeAppointmentFinalise: payload,
      };
    },

    ...requestLoopHandlers({
      action: CREATENOTEBYTYPE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          notes_list: [...state.notes_list, payload],
          create_note_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: (state, payload) => {
        return {
          ...state,
          create_note_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'create_note_state',
    }),

    ...requestLoopHandlers({
      action: UPDATEPATIENTMETRICS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          update_patient_metrics_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: (state, payload) => {
        return {
          ...state,
          update_patient_metrics_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'update_patient_metrics_state',
    }),

    ...requestLoopHandlers({
      action: UPDATENOTEBYTYPE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          notes_list: state.notes_list.map(note => {
            if (note.note_id === payload.note_id) {
              return payload;
            }

            return note;
          }),
          update_note_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: (state, payload) => {
        return {
          ...state,
          update_note_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'update_note_state',
    }),

    ...requestLoopHandlers({
      action: GETNOTESLIST,
      onSuccess: (state, payload) => {
        return {
          ...state,
          notes_list: payload,
          get_notes_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_notes_state',
    }),

    ...requestLoopHandlers({
      action: CREATEAPPOINTMENTROOM,
      onSuccess: (state, payload) => {
        return {
          ...state,
          create_appointment_room_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: (state, payload) => {
        return {
          ...state,
          create_appointment_room_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'create_appointment_room_state',
    }),
  },
  initialState
);
