import apiCall from 'libs/apiCall';

export const getAppointmentsList = (filters, token) =>
  apiCall(
    `/appointment/appointments/?status__in=${filters}`,
    null,
    'GET',
    token
  );

export const updateAppointment = (id, data, token) =>
  apiCall(`/appointment/appointments/${id}/`, data, 'PUT', token);

export const declineAppointment = (id, data, token) =>
  apiCall(`/appointment/appointments/${id}/`, data, 'PUT', token);

export const getAppointmentById = (id, token) =>
  apiCall(`/appointment/appointments/${id}/`, null, 'GET', token);

export const getNextAppointmentDetail = token =>
  apiCall(`/clinician/home/`, null, 'GET', token);

export const createAppointment = (data, token) =>
  apiCall(`/appointment/appointments/`, data, 'POST', token);

export const getClinicianList = token =>
  apiCall(`/clinician/clinicians/`, null, 'GET', token);

export const getAvailabilityListById = (filter, token) =>
  apiCall(`/availability/periods/${filter}`, null, 'GET', token);

export const getClinicianTimeSlots = (data, token) =>
  apiCall(`/appointment/clinician-slots/`, data, 'GET', token, 'raw');

export const getAppointmentDurationOptions = (data, token) =>
  apiCall(`/appointment/appointment-duration-options/`, data, 'GET', token);
