import { takeLatest, call, put } from 'redux-saga/effects';
import { getToken } from 'libs/token';

import {
  getNotificationList,
  markOneReadNotificaion,
  markAllReadNotificaions,
} from 'api/methods/notifications';

import {
  GETNOTIFICATIONLIST,
  MARKONEREADNOTIFICATION,
  MARKALLREADNOTIFICATION,
} from './actionTypes';
import {
  getNotificationlistSuccess,
  getNotificationlistFail,
  markOneReadNotificationSuccess,
  markOneReadNotificationFail,
  markAllReadNotificationSuccess,
  markAllReadNotificationFail,
} from './reducer';

function* get_notification_list() {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getNotificationList, userToken);
  if (response.status === 200) {
    yield put(getNotificationlistSuccess(response.data));
  } else {
    yield put(getNotificationlistFail(response.data));
  }
}

function* make_one_readstats(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    markOneReadNotificaion,
    action.payload.id,
    userToken
  );
  if (response.status === 201) {
    yield put(markOneReadNotificationSuccess(action.payload));
  } else {
    yield put(markOneReadNotificationFail(response.data));
  }
}

function* make_all_readstats(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(markAllReadNotificaions, userToken);
  if (response.status === 201) {
    yield put(markAllReadNotificationSuccess(response.data));
  } else {
    yield put(markAllReadNotificationFail(response.data));
  }
}

export const saga = function*() {
  yield takeLatest(GETNOTIFICATIONLIST, get_notification_list);
  yield takeLatest(MARKONEREADNOTIFICATION, make_one_readstats);
  yield takeLatest(MARKALLREADNOTIFICATION, make_all_readstats);
};
