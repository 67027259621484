import { handleActions, createAction } from 'redux-actions';
import { defineLoopActions, requestLoopHandlers } from 'libs/state';
import { REQUEST_STATUS } from 'config/constants';

import { GETNEWSIGNUPSLIST } from './actionTypes';

const initialState = {
  new_signups_list: [],
  get_new_signups_list_state: REQUEST_STATUS.INITIAL
};

export const {
  start: getNewSignupsList,
  success: getNewSignupsListSuccess,
  fail: getNewSignupsListFail,
} = defineLoopActions(GETNEWSIGNUPSLIST);


export const reportsReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: GETNEWSIGNUPSLIST,
      onSuccess: (state, payload) => {
        return {
          ...state,
          new_signups_list: payload,
          get_new_signups_list_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_new_signups_list_state',
    }),
  },
  initialState
);
