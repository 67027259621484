import { takeLatest, call, put } from 'redux-saga/effects';
import { getToken } from 'libs/token';
import { getNewSignupsList } from 'api/methods/reports';
import { GETNEWSIGNUPSLIST } from './actionTypes';
import { getNewSignupsListSuccess, getNewSignupsListFail } from './reducer';

function* get_new_signups_list(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getNewSignupsList, userToken);
  if (response.status === 200) {
    yield put(getNewSignupsListSuccess(response.data));
  } else {
    yield put(getNewSignupsListFail(response.data));
  }
}

export const saga = function* () {
  yield takeLatest(GETNEWSIGNUPSLIST, get_new_signups_list);
};
