import apiCall from 'libs/apiCall';

export const fetchClinicianPatientPairs = token => {
  return apiCall('/matchmaker/clinician-patient-pairs/', null, 'GET', token);
};

export const fetchClientDetail = (id, token) => {
  return apiCall(`/patient/patients/${id}/`, null, 'GET', token);
};

export const fetchClientDetailArray = token => {
  return apiCall(`/patient/patients/`, null, 'GET', token);
};

export const fetchChatRoomToken = (data, token) => {
  return apiCall(`/appointment/chat-room-token/`, data, 'POST', token);
};

export const fetchChatDetails = token =>
  apiCall(`/chat/chat-rooms/`, null, 'GET', token);

export const setMarkChannelUnread = (data, token) => {
  return apiCall(`/chat/chat-rooms/`, data, 'POST', token);
};
