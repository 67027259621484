import apiCall from 'libs/apiCall';

export const getClinicianDetail = (clinician_id, token) =>
  apiCall(`/clinician/clinicians/${clinician_id}/`, null, 'GET', token);

export const updateClinicianDetail = (clinician_id, data, token) =>
  apiCall(`/clinician/clinicians/${clinician_id}/`, data, 'PUT', token);

export const getAvailabilityList = (filter, token) =>
  apiCall(`/availability/periods/${filter}`, null, 'GET', token);

export const getClinicianAvailableDates = (data, token) =>
  apiCall(`/appointment/feasible-dates/`, data, 'GET', token);

export const createAvailability = (data, token) =>
  apiCall(`/availability/generate/`, data, 'POST', token);

export const updateAppointmentData = (clinician_id, data, token) =>
  apiCall(`/clinician/${clinician_id}/appointment-data/`, data, 'POST', token);

export const resetAvailability = token =>
  apiCall(`/availability/reset/`, null, 'POST', token);

export const resetWeeklyhours = (data, token) =>
  apiCall(`/availability/reset/`, data, 'POST', token);
