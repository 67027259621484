import { handleActions } from 'redux-actions';
import { defineLoopActions, requestLoopHandlers } from 'libs/state';
import { REQUEST_STATUS } from 'config/constants';

import { GETTIMESHEETS } from './actionTypes';

const initialState = {
  time_sheets: [],
  get_time_sheets_state: REQUEST_STATUS.INITIAL
};

export const {
  start: getTimeSheets,
  success: getTimeSheetsSuccess,
  fail: getTimeSheetsFail,
} = defineLoopActions(GETTIMESHEETS);


export const timeSheetsReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: GETTIMESHEETS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          time_sheets: payload,
          get_time_sheets_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: (state, payload) => {
        return {
          ...state,
          get_time_sheets_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_time_sheets_state',
    }),
  },
  initialState
);
