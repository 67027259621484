export const REQUEST_STATUS = {
  INITIAL: 'request_initial',
  PENDING: 'request_pending',
  SUCCESS: 'request_success',
  FAIL: 'request_fail',
};

export const Weeks = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const WeekDays = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
};

export const hours = [
  { id: '0', label: '09:45' },
  { id: '1', label: '10:00' },
  { id: '2', label: '10:15' },
  { id: '3', label: '10:30' },
  { id: '4', label: '10:45' },
  { id: '5', label: '11:00' },
  { id: '6', label: '13:00' },
  { id: '7', label: '13:15' },
  { id: '8', label: '13:30' },
  { id: '9', label: '13:45' },
];

export const appointmentTypes = [
  {
    type: 'CON',
    label: 'Confirmed',
    checked: true,
  },
  {
    type: 'PEN',
    label: 'Pending',
    checked: true,
  },
  {
    type: 'COM',
    label: 'Completed',
    checked: true,
  },
  {
    type: 'CAN',
    label: 'Cancelled',
    checked: false,
  },
  {
    type: 'NOS',
    label: 'No Show / Late Cancel',
    checked: false,
  },
  {
    type: 'AVAILABILITY',
    label: 'My Availability',
    checked: false,
  },
];

export const rangeOption = [
  {
    id: '1',
    label: 'Today',
  },
  {
    id: '2',
    label: 'Yesterday',
  },
  {
    id: '3',
    label: 'This Week',
  },
  {
    id: '4',
    label: 'Last 7 Days',
  },
  {
    id: '5',
    label: 'All Time',
  },
  {
    id: '6',
    label: 'Custom Range...',
  },
];

export const notesTypeOption = [
  {
    id: '1',
    label: 'Initial Note',
    type: 'INIT',
  },
  {
    id: '2',
    label: 'Follow-up Note',
    type: 'FOLL',
  },
  {
    id: '3',
    label: 'Psychotherapy note',
    type: 'PSYC',
  },
];

export const declineReason = [
  {
    id: '1',
    label: 'Time Conflicts',
    value: 'TIC',
  },
  {
    id: '2',
    label: 'Other',
    value: 'OTH',
  },
];

export const lateCancellationOptions = [
  {
    id: '1',
    label: 'No',
    value: false,
  },
  {
    id: '2',
    label: 'Yes',
    value: true,
  },
];

export const genderOptions = [
  {
    id: '1',
    label: 'Male',
    value: 'M',
  },
  {
    id: '2',
    label: 'Female',
    value: 'F',
  },
  {
    id: '3',
    label: 'Transgender Male',
    value: 'TM',
  },
  {
    id: '4',
    label: 'Transgender Female',
    value: 'TF',
  },
  {
    id: '5',
    label: 'Gender Queer',
    value: 'GQ',
  },
  {
    id: '6',
    label: 'Gender Variant',
    value: 'GV',
  },
  {
    id: '7',
    label: 'Gender Other',
    value: 'GO',
  },
  {
    id: '8',
    label: 'Non - binary',
    value: 'NB',
  },
];

export const genderPronOptions = [
  {
    id: '1',
    label: 'He/Him/His',
    value: 'H',
  },
  {
    id: '2',
    label: 'She/Her/Hers',
    value: 'S',
  },
  {
    id: '3',
    label: 'They/Them/Their',
    value: 'T',
  },
];

export const raceOptions = [
  {
    id: '1',
    label: 'White/Caucasian',
    value: 'W',
  },
  {
    id: '2',
    label: 'Hispanic or Latino',
    value: 'HL',
  },
  {
    id: '3',
    label: 'Black or Africa American',
    value: 'B',
  },
  {
    id: '4',
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'NH',
  },
  {
    id: '5',
    label: 'Asian',
    value: 'A',
  },
  {
    id: '6',
    label: 'American Indian or Alaska Native',
    value: 'AI',
  },
  {
    id: '7',
    label: 'Two or More Races',
    value: 'TR',
  },
];

export const sexOptions = [
  {
    id: '1',
    label: 'Male',
    value: 'M',
  },
  {
    id: '2',
    label: 'Female',
    value: 'F',
  },
];

export const sexOrienOptions = [
  {
    id: '1',
    label: 'Heterosexual/Straight',
    value: 'S',
  },
  {
    id: '2',
    label: 'Gay',
    value: 'G',
  },
  {
    id: '3',
    label: 'Lesbian',
    value: 'L',
  },
  {
    id: '4',
    label: 'Bisexual',
    value: 'B',
  },
  {
    id: '5',
    label: 'Asexual',
    value: 'A',
  },
  {
    id: '6',
    label: 'Pansexual',
    value: 'P',
  },
  {
    id: '7',
    label: 'Queer',
    value: 'Q',
  },
];

export const maritalStatusOptions = [
  {
    id: '1',
    label: 'Single',
    value: 'S',
  },
  {
    id: '2',
    label: 'Married',
    value: 'M',
  },
  {
    id: '3',
    label: 'Divorced',
    value: 'D',
  },
  {
    id: '4',
    label: 'Widowed',
    value: 'W',
  },
];

export const notificationPreferenceOptions = [
  {
    id: '1',
    label: 'SMS',
    value: 'SMS',
  },
  {
    id: '2',
    label: 'Email',
    value: 'EMAIL',
  },
  {
    id: '3',
    label: 'SMS & Email',
    value: 'SMS_EM',
  },
];

export const appointmentCancellationTypes = {
  DEC: 'Declined',
  RES: 'Rescheduled',
  NOS: 'No-Show',
};

export const MFA_LOGIN_ENABLED =
  process.env.REACT_APP_MFA_LOGIN_ENABLED || false;

export const SUPPORT_EMAIL = 'support@talkdoc.co';

export const careTeamPairStatusOptions = [
  {
    id: '1',
    label: 'Active',
    value: true,
  },
  {
    id: '2',
    label: 'Inactive',
    value: false,
  },
];

export const telehealthConsentStatusOptions = [
  {
    id: '1',
    label: 'Not Sent',
    value: 'NOT_SENT',
  },
  {
    id: '2',
    label: 'Sent',
    value: 'SENT',
  },
  {
    id: '3',
    label: 'Signed',
    value: 'SIGNED',
  },
  {
    id: '4',
    label: 'Sent External',
    value: 'SENT_EXTERNAL',
  },
];

export const serviceTypeOptions = [
  {
    id: '1',
    label: 'Therapy',
    value: 'THE',
  },
  {
    id: '2',
    label: 'Psychiatry',
    value: 'PSY',
  },
];

export const eligibilityCheckTypeOptions = [
  {
    id: '1',
    label: 'Eligibility',
    value: 'ELIGIBILITY',
  },
  {
    id: '2',
    label: 'Authorization',
    value: 'AUTHORIZATION',
  },
];

export const eligibilityStatusOptions = [
  {
    id: '1',
    label: 'Eligible',
  },
  {
    id: '2',
    label: 'Not Eligible',
  },
];

export const consentFormStatusTypes = {
  NOT_SENT: 'Not Sent',
  SENT: 'Sent',
  SIGNED: 'Signed',
  SENT_EXTERNAL: 'Sent External',
};

export const consentFormTypes = {
  TELEHEALTH: 'Telehealth Consent',
  VIDEO_RECORD: 'Video Recording Consent',
};

export const editAppointmentStatusOptions = [
  {
    id: '1',
    label: 'Confirmed',
    value: 'CON',
  },
  {
    id: '2',
    label: 'Completed',
    value: 'COM',
  },
  {
    id: '3',
    label: 'No Show',
    value: 'CAN',
  },
];

export const patientStatusOptions = [
  {
    id: '1',
    label: 'Active',
    value: true,
  },
  {
    id: '2',
    label: 'Inactive',
    value: false,
  },
];

export const patientInactiveTypeOptions = [
  {
    id: '1',
    label: 'Terminated',
    value: 'TERMINATED',
  },
  {
    id: '2',
    label: 'No Longer Interested',
    value: 'NOT_INTERESTED',
  },
  {
    id: '3',
    label: 'Not Eligible',
    value: 'NOT_ELIGIBLE',
  },
];

export const clientTaskTypeOptions = [
  {
    id: '1',
    label: 'Audio/Video Test',
    value: 'AUDIO_VIDEO_TEST',
  },
  {
    id: '2',
    label: 'Add Payment Option',
    value: 'ADD_PAYMENT_OPTION',
  },
];
export const serviceSelectionOptions = [
  {
    id: '1',
    label: 'Therapy',
    value: 'THE',
  },
  {
    id: '2',
    label: 'Psychiatry',
    value: 'PSY',
  },
  {
    id: '3',
    label: 'Therapy and Psychiatry',
    value: 'THE_PSY',
  },
];

export const diagCodeCategoryOptions = [
  {
    id: '1',
    label: 'Psychotic',
    value: 'PSYCHOTIC',
  },
  {
    id: '2',
    label: 'Depressive',
    value: 'DEPRESSIVE',
  },
  {
    id: '3',
    label: 'Bipolar',
    value: 'BIPOLAR',
  },
  {
    id: '4',
    label: 'Childhood',
    value: 'CHILDHOOD',
  },
  {
    id: '5',
    label: 'Miscellaneous',
    value: 'MISC',
  },
];

export const clientPaymentTypeOptions = [
  {
    id: '1',
    label: 'Cash',
    value: 'CASH',
  },
  {
    id: '2',
    label: 'Insurance',
    value: 'INSURANCE',
  },
  {
    id: '3',
    label: 'Cash and Insurance',
    value: 'CASH_AND_INSURANCE',
  },
];
