const namespace = 'talkdoc/livesession';

export const COMPLETEAPPOINTMENT = `${namespace}/completeappointment`;
export const UPDATEPARTICIPANTCONNECTIONEVENT = `${namespace}/updateparticipantconnectionevent`;
export const OPENVIDEOCHAT = `${namespace}/openvideochat`;
export const GETNOTESLIST = `${namespace}/getnoteslist`;
export const CREATENOTEBYTYPE = `${namespace}/createnotebytype`;
export const UPDATENOTEBYTYPE = `${namespace}/updatenotebytype`;
export const UPDATEVIDEOCALLMODE = `${namespace}/updatevideocallmode`;
export const COMPLETEAPPOINTMENTCLICKED = `${namespace}/completeappointmentclicked`;
export const COMPLETEAPPOINTMENTFINALISE = `${namespace}/completeappointmentfinalise`;
export const UPDATEPATIENTMETRICS = `${namespace}/updatepatientmetrics`;
export const CREATEAPPOINTMENTROOM = `${namespace}/createappointmentroom`;
