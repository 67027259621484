// General api to access data
import axios from 'axios';
import ApiConstants from 'api/ApiConstants';
export default async function apiCall(
  path = '',
  data = null,
  method = 'GET',
  token = null,
  requestType = null
) {
  const dataOrParams = ['GET'].includes(method) ? 'params' : 'data';

  let url = ApiConstants.BASE_URL + path;

  console.log(url, data, token, requestType);

  return axios
    .request({
      url,
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
        ...(token && { Authorization: token }),
      },
      ...(requestType === 'auth' &&
        data !== null && {
          auth: {
            username: data.email,
            password: data.password,
          },
        }),
      [dataOrParams]: data,
      withCredentials: true,
    })
    .then(resp => resp)
    .catch(error => error.response);
}
