// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { history } from './store/history';
import configureStore from 'store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import { Integrations } from '@sentry/tracing';
import { projectEnv, reCaptchaKey } from './config/env';
const { persistor, store } = configureStore();

// DO NOT log sentry for local
if (projectEnv !== 'local') {
  LogRocket.init('talkdoc/talkdoc-clinician-fe');
  Sentry.init({
    dsn:
      'https://ac500cc34912476faaac6cf58d1e49b4@o718363.ingest.sentry.io/5862066',
    integrations: [new Integrations.BrowserTracing()],
    environment: projectEnv,
    // TODO: lower sample rate when traffic increases
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} useEnterprise="true"> */}
      <Router history={history}>
        <App />
      </Router>
      {/* </GoogleReCaptchaProvider> */}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
