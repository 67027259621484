import React from 'react';
const Login = React.lazy(() => import('views/auth/Login'));
const MfaLogin = React.lazy(() => import('views/auth/MfaLogin'));
const Signup = React.lazy(() => import('views/auth/Signup'));
const EmailVerification = React.lazy(() =>
  import('views/auth/EmailVerification')
);
const PhoneVerification = React.lazy(() =>
  import('views/auth/PhoneVerification')
);
const OnboardingMainPanel = React.lazy(() =>
  import('views/onboarding/MainPanel')
);

const Dashboard = React.lazy(() => import('views/dashboard'));
const LiveSession = React.lazy(() => import('views/LiveSession'));
const ForgotPwdRequest = React.lazy(() =>
  import('views/auth/ForgotPwdRequest')
);

const ResetPassword = React.lazy(() => import('views/auth/ResetPassword'));

const Notes = React.lazy(() =>
  import('components/LiveSession/Container/NoteEditor')
);

const WentWrong = React.lazy(() => import('views/Errors/WentWrong'));

const NotFound = React.lazy(() => import('views/Errors/NotFound'));

const Contact = React.lazy(() => import('views/contact/Contact'));

const Resources = React.lazy(() => import('views/resources/Resources'));

const AppRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    private: false,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    private: false,
  },
  {
    path: '/forgot-password',
    name: 'ResetPassword',
    component: ForgotPwdRequest,
    private: false,
  },
  {
    path: '/reset-password/:emailb64/:activation_token',
    name: 'ForgotPassword',
    component: ResetPassword,
    private: false,
  },

  {
    path: '/signup/:emailb64/:activation_token',
    name: 'Signup',
    component: Signup,
    private: false,
  },
  {
    path: '/confirmlogin',
    name: 'MfaLogin',
    component: MfaLogin,
    private: true,
  },

  {
    path: '/email-verification',
    name: 'EmailVerification',
    component: EmailVerification,
    private: true,
  },

  {
    path: '/phone-verification',
    name: 'PhoneVerification',
    component: PhoneVerification,
    private: true,
  },

  {
    path: '/onboarding',
    name: 'OnboardingMainPanel',
    component: OnboardingMainPanel,
    private: true,
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    private: true,
  },

  {
    path: '/livesession',
    name: 'LiveSession',
    component: LiveSession,
    private: true,
  },

  {
    path: '/notes',
    name: 'Notes',
    component: Notes,
    private: true,
  },

  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    private: true,
  },

  {
    path: '/401',
    name: 'WentWrong',
    component: WentWrong,
    private: false,
  },

  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    private: false,
  },

  {
    path: '/resources',
    name: 'Resources',
    component: Resources,
    private: false,
  },

  { redirect: true, path: '/', to: '/', name: 'Login' },
];

export default AppRoutes;
