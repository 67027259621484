import apiCall from 'libs/apiCall';

export const getBillingEntries = (data, token) =>
  apiCall('/appointment/billing-entries/', data, 'GET', token);

export const editBillingEntry = (data, token) =>
  apiCall('/appointment/billing-entries/', data, 'POST', token);

export const createBillingEntry = (data, token) =>
  apiCall('/appointment/billing-entries/', data, 'PUT', token);

export const getAppointmentCodes = (data, token) =>
  apiCall('/appointment/appointment-codes/', data, 'GET', token);