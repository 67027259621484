const namespace = 'talkdoc/clients';

export const GETCLIENTLIST = `${namespace}/getclientlist`;
export const GETCLIENTINFOBYID = `${namespace}/getclientinfobyid`;
export const UPDATECLIENTINFO = `${namespace}/updateclientinfo`;
export const GETCLIENTAPPOINTMENTS = `${namespace}/getclientappointments`;
export const GETCLIENTNNOTES = `${namespace}/getclientnotes`;
export const GETCLIENTSURVEYS = `${namespace}/getclientsurveys`;
export const GETCLIENTSURVEYRESPONSES = `${namespace}/getclientsurveyresponses`;
export const CREATECLIENTNOTE = `${namespace}/createclientnote`;
export const UPDATECLIENTNOTE = `${namespace}/updateclientnote`;
export const GETCARECOORDINATORSTATUS = `${namespace}/getcarecoordinatorstatus`;
export const UPDATECARECOORDINATORSTATUS = `${namespace}/updatecarecoordinatorstatus`;
export const GETCARETEAMENTRIES = `${namespace}/getcareteamentries`;
export const CREATEORUPDATECARETEAMENTRY = `${namespace}/createorupdatecareteamentry`;
export const DELETECARETEAMENTRY = `${namespace}/deletecareteamentry`;
export const GETELIGIBILITYCHECKENTRIES = `${namespace}/geteligibilitycheckentries`;
export const CREATEORUPDATEELIGIBILITYCHECKENTRY = `${namespace}/createorupdateeligibilitycheckentry`;
export const DELETEELIGIBILITYCHECKENTRY = `${namespace}/deleteeligibilitycheckentry`;
export const GETCONSENTDOCUMENTS = `${namespace}/getconsentdocuments`;
export const GETCLIENTSEARCHOPTIONS = `${namespace}/getclientsearchoptions`;
export const FETCHHEALTHPLANS = `${namespace}/fetchhealthplans`;
export const GETCLIENTTASKS = `${namespace}/getclienttasks`;
export const SENDCLIENTREMINDER = `${namespace}/sendclientreminder`;
export const CREATEPATIENT = `${namespace}/createpatient`;
export const SENDAPPOINTMENTPAYMENTLINK = `${namespace}/sendappointmentpaymentlink`;
export const GETUPLOADEDMEDICAIDIDURL = `${namespace}/getuploadedmedicaididurl`;
export const DOWNLOADCONSENTDOCUMENT = `${namespace}/downloadconsentdocument`;
