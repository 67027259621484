import { handleActions } from 'redux-actions';
import { defineLoopActions, requestLoopHandlers } from 'libs/state';
import { REQUEST_STATUS } from 'config/constants';

import {
  GETCLIENTLIST,
  GETCLIENTINFOBYID,
  UPDATECLIENTINFO,
  GETCLIENTAPPOINTMENTS,
  GETCLIENTNNOTES,
  GETCLIENTSURVEYS,
  GETCLIENTSURVEYRESPONSES,
  CREATECLIENTNOTE,
  UPDATECLIENTNOTE,
  GETCARECOORDINATORSTATUS,
  UPDATECARECOORDINATORSTATUS,
  GETCARETEAMENTRIES,
  CREATEORUPDATECARETEAMENTRY,
  DELETECARETEAMENTRY,
  GETELIGIBILITYCHECKENTRIES,
  CREATEORUPDATEELIGIBILITYCHECKENTRY,
  DELETEELIGIBILITYCHECKENTRY,
  GETCONSENTDOCUMENTS,
  GETCLIENTSEARCHOPTIONS,
  FETCHHEALTHPLANS,
  GETCLIENTTASKS,
  SENDCLIENTREMINDER,
  CREATEPATIENT,
  SENDAPPOINTMENTPAYMENTLINK,
  GETUPLOADEDMEDICAIDIDURL,
  DOWNLOADCONSENTDOCUMENT,
} from './actionTypes';

const initialState = {
  client_list: [],
  client_list_num_pages: 1,
  client_info: {},
  appointment_list: [],
  note_list: [],
  survey_list: [],
  survey_response_list: [],
  get_clientlist_state: REQUEST_STATUS.INITIAL,
  get_clientinfo_state: REQUEST_STATUS.INITIAL,
  update_clientinfo_state: REQUEST_STATUS.INITIAL,
  get_clientappointment_state: REQUEST_STATUS.INITIAL,
  get_clientnote_state: REQUEST_STATUS.INITIAL,
  get_clientsurvey_state: REQUEST_STATUS.INITIAL,
  get_clientsurveyresponse_state: REQUEST_STATUS.INITIAL,
  create_clientnote_state: REQUEST_STATUS.INITIAL,
  update_clientnote_state: REQUEST_STATUS.INITIAL,
  is_care_coordinator_active: null,
  care_team_entries: [],
  eligibility_check_entries: [],
  consent_documents: [],
  client_search_options: [],
  get_care_team_entries_state: REQUEST_STATUS.INITIAL,
  create_or_update_care_team_entry_state: REQUEST_STATUS.INITIAL,
  delete_care_team_entry_state: REQUEST_STATUS.INITIAL,
  get_eligibility_check_entries_state: REQUEST_STATUS.INITIAL,
  create_or_update_eligibility_check_entry_state: REQUEST_STATUS.INITIAL,
  delete_eligibility_check_entry_state: REQUEST_STATUS.INITIAL,
  get_consent_documents_state: REQUEST_STATUS.INITIAL,
  get_clientsearchoptions_state: REQUEST_STATUS.INITIAL,
  health_plans: [],
  get_healthplans_state: REQUEST_STATUS.INITIAL,
  client_tasks: [],
  get_client_tasks_state: REQUEST_STATUS.INITIAL,
  create_client_task_state: REQUEST_STATUS.INITIAL,
  send_client_reminder_state: REQUEST_STATUS.INITIAL,
  create_patient_state: REQUEST_STATUS.INITIAL,
  send_appointment_payment_link_state: REQUEST_STATUS.INITIAL,
  uploaded_medicaid_id_url: '',
  get_uploaded_medicaid_id_url_state: REQUEST_STATUS.INITIAL,
  consent_document_download_url: '',
  download_consent_document_state: REQUEST_STATUS.INITIAL,
};

export const {
  start: getClientList,
  success: getClientListSuccess,
  fail: getClientListFail,
} = defineLoopActions(GETCLIENTLIST);

export const {
  start: getClientInfo,
  success: getClientInfoSuccess,
  fail: getClientInfoFail,
} = defineLoopActions(GETCLIENTINFOBYID);

export const {
  start: updateClientInfo,
  success: updateClientInfoSuccess,
  fail: updateClientInfoFail,
} = defineLoopActions(UPDATECLIENTINFO);

export const {
  start: getClientAppointments,
  success: getClientAppointmentsSuccess,
  fail: getClientAppointmentsFail,
} = defineLoopActions(GETCLIENTAPPOINTMENTS);

export const {
  start: getClientNotes,
  success: getClientNotesSuccess,
  fail: getClientNotesFail,
} = defineLoopActions(GETCLIENTNNOTES);

export const {
  start: getClientSurveys,
  success: getClientSurveysSuccess,
  fail: getClientSurveysFail,
} = defineLoopActions(GETCLIENTSURVEYS);

export const {
  start: getClientSurveyResponses,
  success: getClientSurveyResponsesSuccess,
  fail: getClientSurveyResponsesFail,
} = defineLoopActions(GETCLIENTSURVEYRESPONSES);

export const {
  start: createClientNote,
  success: createClientNoteSuccess,
  fail: createClientNoteFail,
} = defineLoopActions(CREATECLIENTNOTE);

export const {
  start: updateClientNote,
  success: updateClientNoteSuccess,
  fail: updateClientNoteFail,
} = defineLoopActions(UPDATECLIENTNOTE);

export const {
  start: getCareCoordinatorStatus,
  success: getCareCoordinatorStatusSuccess,
  fail: getCareCoordinatorStatusFail,
} = defineLoopActions(GETCARECOORDINATORSTATUS);

export const {
  start: updateCareCoordinatorStatus,
  success: updateCareCoordinatorStatusSuccess,
  fail: updateCareCoordinatorStatusFail,
} = defineLoopActions(UPDATECARECOORDINATORSTATUS);

export const {
  start: getCareTeamEntries,
  success: getCareTeamEntriesSuccess,
  fail: getCareTeamEntriesFail,
} = defineLoopActions(GETCARETEAMENTRIES);

export const {
  start: createOrUpdateCareTeamEntry,
  success: createOrUpdateCareTeamEntrySuccess,
  fail: createOrUpdateCareTeamEntryFail,
} = defineLoopActions(CREATEORUPDATECARETEAMENTRY);

export const {
  start: deleteCareTeamEntry,
  success: deleteCareTeamEntrySuccess,
  fail: deleteCareTeamEntryFail,
} = defineLoopActions(DELETECARETEAMENTRY);

export const {
  start: getEligibilityCheckEntries,
  success: getEligibilityCheckEntriesSuccess,
  fail: getEligibilityCheckEntriesFail,
} = defineLoopActions(GETELIGIBILITYCHECKENTRIES);

export const {
  start: createOrUpdateEligibilityCheckEntry,
  success: createOrUpdateEligibilityCheckEntrySuccess,
  fail: createOrUpdateEligibilityCheckEntryFail,
} = defineLoopActions(CREATEORUPDATEELIGIBILITYCHECKENTRY);

export const {
  start: deleteEligibilityCheckEntry,
  success: deleteEligibilityCheckEntrySuccess,
  fail: deleteEligibilityCheckEntryFail,
} = defineLoopActions(DELETEELIGIBILITYCHECKENTRY);

export const {
  start: getConsentDocuments,
  success: getConsentDocumentsSuccess,
  fail: getConsentDocumentsFail,
} = defineLoopActions(GETCONSENTDOCUMENTS);

export const {
  start: getClientSearchOptions,
  success: getClientSearchOptionsSuccess,
  fail: getClientSearchOptionsFail,
} = defineLoopActions(GETCLIENTSEARCHOPTIONS);

export const {
  start: fetchHealthPlans,
  success: fetchHealthPlansSuccess,
  fail: fetchHealthPlansFail,
} = defineLoopActions(FETCHHEALTHPLANS);

export const {
  start: getClientTasks,
  success: getClientTasksSuccess,
  fail: getClientTasksFail,
} = defineLoopActions(GETCLIENTTASKS);

export const {
  start: sendClientReminder,
  success: sendClientReminderSuccess,
  fail: sendClientReminderFail,
} = defineLoopActions(SENDCLIENTREMINDER);

export const {
  start: createPatient,
  success: createPatientSuccess,
  fail: createPatientFail,
} = defineLoopActions(CREATEPATIENT);

export const {
  start: sendAppointmentPaymentLink,
  success: sendAppointmentPaymentLinkSuccess,
  fail: sendAppointmentPaymentLinkFail,
} = defineLoopActions(SENDAPPOINTMENTPAYMENTLINK);

export const {
  start: getUploadedMedicaidIdUrl,
  success: getUploadedMedicaidIdUrlSuccess,
  fail: getUploadedMedicaidIdUrlFail,
} = defineLoopActions(GETUPLOADEDMEDICAIDIDURL);

export const {
  start: downloadConsentDocument,
  success: downloadConsentDocumentSuccess,
  fail: downloadConsentDocumentFail,
} = defineLoopActions(DOWNLOADCONSENTDOCUMENT);

export const clientReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: GETCLIENTLIST,
      onSuccess: (state, payload) => {
        return {
          ...state,
          client_list: payload.data,
          client_list_num_pages: payload.num_pages,
          get_clientlist_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clientlist_state',
    }),

    ...requestLoopHandlers({
      action: GETCLIENTINFOBYID,
      onSuccess: (state, payload) => {
        return {
          ...state,
          client_info: payload,
          get_clientinfo_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clientinfo_state',
    }),

    ...requestLoopHandlers({
      action: UPDATECLIENTINFO,
      onSuccess: (state, payload) => {
        return {
          ...state,
          client_info: payload,
          client_list: state.client_list.map(item => {
            if (item.user.user_id === payload.user.user_id) {
              return payload;
            } else {
              return item;
            }
          }),
          update_clientinfo_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'update_clientinfo_state',
    }),

    ...requestLoopHandlers({
      action: GETCLIENTAPPOINTMENTS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          appointment_list: payload,
          get_clientappointment_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clientappointment_state',
    }),

    ...requestLoopHandlers({
      action: GETCLIENTNNOTES,
      onSuccess: (state, payload) => {
        return {
          ...state,
          note_list: payload,
          get_clientnote_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clientnote_state',
    }),

    ...requestLoopHandlers({
      action: GETCLIENTSURVEYS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          survey_list: payload,
          get_clientsurvey_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clientsurvey_state',
    }),

    ...requestLoopHandlers({
      action: GETCLIENTSURVEYRESPONSES,
      onSuccess: (state, payload) => {
        return {
          ...state,
          survey_response_list: payload,
          get_clientsurveyresponse_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clientsurveyresponse_state',
    }),

    ...requestLoopHandlers({
      action: CREATECLIENTNOTE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          note_list: [...state.note_list, payload],
          create_clientnote_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'create_clientnote_state',
    }),

    ...requestLoopHandlers({
      action: UPDATECLIENTNOTE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          note_list: state.note_list.map(note => {
            if (note.note_id === payload.note_id) {
              return payload;
            }

            return note;
          }),
          update_clientnote_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'update_clientnote_state',
    }),

    ...requestLoopHandlers({
      action: GETCARECOORDINATORSTATUS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          is_care_coordinator_active: payload,
        };
      },
    }),

    ...requestLoopHandlers({
      action: UPDATECARECOORDINATORSTATUS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          is_care_coordinator_active: payload,
        };
      },
    }),

    ...requestLoopHandlers({
      action: GETCARETEAMENTRIES,
      onSuccess: (state, payload) => {
        return {
          ...state,
          care_team_entries: payload,
          get_care_team_entries_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_care_team_entries_state',
    }),

    ...requestLoopHandlers({
      action: CREATEORUPDATECARETEAMENTRY,
      onSuccess: (state, payload) => {
        return {
          ...state,
          care_team_entries: payload,
          create_or_update_care_team_entry_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'create_or_update_care_team_entry_state',
    }),

    ...requestLoopHandlers({
      action: DELETECARETEAMENTRY,
      onSuccess: (state, payload) => {
        return {
          ...state,
          care_team_entries: payload,
          delete_care_team_entry_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'delete_care_team_entry_state',
    }),

    ...requestLoopHandlers({
      action: GETELIGIBILITYCHECKENTRIES,
      onSuccess: (state, payload) => {
        return {
          ...state,
          eligibility_check_entries: payload,
          get_eligibility_check_entries_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_eligibility_check_entries_state',
    }),

    ...requestLoopHandlers({
      action: CREATEORUPDATEELIGIBILITYCHECKENTRY,
      onSuccess: (state, payload) => {
        return {
          ...state,
          eligibility_check_entries: payload,
          create_or_update_eligibility_check_entry_state:
            REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'create_or_update_eligibility_check_entry_state',
    }),

    ...requestLoopHandlers({
      action: DELETEELIGIBILITYCHECKENTRY,
      onSuccess: (state, payload) => {
        return {
          ...state,
          eligibility_check_entries: payload,
          delete_eligibility_check_entry_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'delete_eligibility_check_entry_state',
    }),

    ...requestLoopHandlers({
      action: GETCONSENTDOCUMENTS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          consent_documents: payload,
          get_consent_documents_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_consent_documents_state',
    }),

    ...requestLoopHandlers({
      action: GETCLIENTSEARCHOPTIONS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          client_search_options: payload,
          get_clientsearchoptions_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clientsearchoptions_state',
    }),

    ...requestLoopHandlers({
      action: FETCHHEALTHPLANS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          health_plans: payload,
          get_healthplans_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_healthplans_state',
    }),
    ...requestLoopHandlers({
      action: GETCLIENTTASKS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          client_tasks: payload,
          get_client_tasks_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_client_tasks_state',
    }),
    ...requestLoopHandlers({
      action: SENDCLIENTREMINDER,
      onSuccess: (state, payload) => {
        return {
          ...state,
          send_client_reminder_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: state => {
        return {
          ...state,
          send_client_reminder_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'send_client_reminder_state',
    }),
    ...requestLoopHandlers({
      action: CREATEPATIENT,
      onSuccess: (state, payload) => {
        return {
          ...state,
          client_list: [...state.client_list, payload],
          create_patient_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: state => {
        return {
          ...state,
          create_patient_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'create_patient_state',
    }),
    ...requestLoopHandlers({
      action: SENDAPPOINTMENTPAYMENTLINK,
      onSuccess: (state, payload) => {
        return {
          ...state,
          send_appointment_payment_link_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: state => {
        return {
          ...state,
          send_appointment_payment_link_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'send_appointment_payment_link_state',
    }),
    ...requestLoopHandlers({
      action: GETUPLOADEDMEDICAIDIDURL,
      onSuccess: (state, payload) => {
        return {
          ...state,
          uploaded_medicaid_id_url: payload,
          get_uploaded_medicaid_id_url_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: state => {
        return {
          ...state,
          uploaded_medicaid_id_url: '',
          get_uploaded_medicaid_id_url_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'get_uploaded_medicaid_id_url_state',
    }),

    ...requestLoopHandlers({
      action: DOWNLOADCONSENTDOCUMENT,
      onSuccess: (state, payload) => {
        return {
          ...state,
          consent_document_download_url: payload,
          download_consent_document_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: state => {
        return {
          ...state,
          consent_document_download_url: '',
          download_consent_document_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'download_consent_document_state',
    }),
  },
  initialState
);
