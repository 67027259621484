import Cookies from 'universal-cookie';

// export const tokenName = 'cliniciantoken';
export const tokenName = 'authToken';
const cookies = new Cookies();
export const getToken = () => {
  return cookies.get(tokenName);
};

export const setToken = token => {
  cookies.set(tokenName, token, { path: '/' });
};

export const removeToken = () => {
  cookies.remove(tokenName);
};

export const getAll = () => {
  return cookies.getAll();
};
