import apiCall from 'libs/apiCall';

export const completeAppointment = (id, data, token) =>
  apiCall(`/appointment/appointments/${id}/`, data, 'PUT', token);

export const updatePatientMetrics = (data, token) =>
  apiCall(`/appointment/update-patient-metrics/`, data, 'POST', token);

export const createAppointmentRoom = (data, token) =>
  apiCall(`/appointment/create-appointment-room/`, data, 'GET', token);
