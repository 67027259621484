import { handleActions, createAction } from 'redux-actions';
import { defineLoopActions, requestLoopHandlers } from 'libs/state';
import { REQUEST_STATUS } from 'config/constants';

import {
  GETNOTIFICATIONLIST,
  ADDRECENTNOTIFICATION,
  MARKALLREADNOTIFICATION,
  MARKONEREADNOTIFICATION,
} from './actionTypes';

const initialState = {
  notification_list: [],
  get_notificationlist_state: REQUEST_STATUS.INITIAL,
  mark_onenotification_state: REQUEST_STATUS.INITIAL,
  mark_allnotification_state: REQUEST_STATUS.INITIAL,
};

export const addRecentNotification = createAction(ADDRECENTNOTIFICATION);
// export const markOneReadNotification = createAction(MARKONEREADNOTIFICATION);

export const {
  start: getNotificationlist,
  success: getNotificationlistSuccess,
  fail: getNotificationlistFail,
} = defineLoopActions(GETNOTIFICATIONLIST);

export const {
  start: markOneReadNotification,
  success: markOneReadNotificationSuccess,
  fail: markOneReadNotificationFail,
} = defineLoopActions(MARKONEREADNOTIFICATION);

export const {
  start: markAllReadNotification,
  success: markAllReadNotificationSuccess,
  fail: markAllReadNotificationFail,
} = defineLoopActions(MARKALLREADNOTIFICATION);

export const notificationsReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: GETNOTIFICATIONLIST,
      onSuccess: (state, payload) => {
        return {
          ...state,
          notification_list: payload.results,
          get_notificationlist_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_notificationlist_state',
    }),

    ...requestLoopHandlers({
      action: MARKONEREADNOTIFICATION,
      onSuccess: (state, payload) => {
        return {
          ...state,
          notification_list: state.notification_list.map(item => {
            if (item.notification_id === payload.id) {
              return { ...item, read_status: true };
            } else {
              return item;
            }
          }),
          mark_onenotification_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'mark_onenotification_state',
    }),

    ...requestLoopHandlers({
      action: MARKALLREADNOTIFICATION,
      onSuccess: (state, payload) => {
        return {
          ...state,
          notification_list: state.notification_list.map(item => {
            return { ...item, read_status: true };
          }),
          mark_allnotification_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'mark_allnotification_state',
    }),

    [ADDRECENTNOTIFICATION]: (state, { payload }) => {
      return {
        ...state,
        notification_list: [payload.payload, ...state.notification_list],
      };
    },
  },
  initialState
);
