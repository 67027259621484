import { takeLatest, call, put } from 'redux-saga/effects';
import { getToken } from 'libs/token';
import { all } from '@redux-saga/core/effects';

import {
  fetchClinicianPatientPairs,
  fetchClientDetail,
  fetchChatRoomToken,
  fetchChatDetails,
} from 'api/methods/messageCenter';

import {
  GETCHATDETAILS,
  GETCHATROOMTOKENPAIRS,
  GETCLIENTDETAILARRAY,
  GETCLINICIANPATIENTPAIRS,
  MARKCHANNELUNREAD,
} from './actionTypes';
import {
  getClinicianPatientPairsSuccess,
  getClinicianPatientPairsFail,
  getClientDetailArraySuccess,
  getClientDetailArrayFail,
  getChatRoomTokenPairsSuccess,
  getChatRoomTokenPairsFail,
  getChatDetailsSuccess,
  getChatDetailsFail,
  markChannelUnreadSuccess,
  markChannelUnreadFail,
} from './reducer';
import { setMarkChannelUnread } from '../../../../api/methods/messageCenter';

function* get_clinician_patient_pairs(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    fetchClinicianPatientPairs,
    userToken,
  );
  if (response.status === 200) {
    yield put(getClinicianPatientPairsSuccess(response.data));
  } else {
    yield put(getClinicianPatientPairsFail(response.data));
  }
}

function* get_client_detail_array(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield all(
    action.payload.clients.map(detail =>
      call(fetchClientDetail, detail.patient_id, userToken),
    ),
  );
  if (response) {
    yield put(
      getClientDetailArraySuccess(
        response.map(clientDetail => clientDetail.data),
      ),
    );
  } else {
    yield put(getClientDetailArrayFail(response));
  }
}

function* get_chat_room_token_pair_details(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield all(
    action.payload.pairs.map(pair =>
      call(
        fetchChatRoomToken,
        {
          patient_user_id: pair.patient_user_id,
          clinician_user_id: pair.clinician_user_id,
        },
        userToken,
      ),
    ),
  );
  if (response) {
    yield put(
      getChatRoomTokenPairsSuccess(
        response.map(chatRoomTokenData => chatRoomTokenData.data),
      ),
    );
  } else {
    yield put(getChatRoomTokenPairsFail(response));
  }
}

function* get_chat_details(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    fetchChatDetails,
    userToken,
  );
  if (response.status === 200) {
    yield put(getChatDetailsSuccess(response.data));
  } else {
    yield put(getChatDetailsFail(response.data));
  }
}

function* set_mark_channel_unread(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    setMarkChannelUnread,
    action.payload,
    userToken,
  );
  if (response.status === 200) {
    yield put(markChannelUnreadSuccess(response.data));
  } else {
    yield put(markChannelUnreadFail(response.data));
  }
}

export const saga = function* () {
  yield takeLatest(GETCLINICIANPATIENTPAIRS, get_clinician_patient_pairs);
  yield takeLatest(GETCLIENTDETAILARRAY, get_client_detail_array);
  yield takeLatest(GETCHATROOMTOKENPAIRS, get_chat_room_token_pair_details);
  yield takeLatest(GETCHATDETAILS, get_chat_details);
  yield takeLatest(MARKCHANNELUNREAD, set_mark_channel_unread);
};
