const namespace = 'talkdoc/onboarding';

export const GETCLINICIANDETAIL = `${namespace}/getcliniciandetail`;
export const UPDATECLINICIANDETAIL = `${namespace}/updatecliniciandetail`;
export const GETAVAILABILITYLIST = `${namespace}/getavailabilitylist`;
export const CREATEAVAILABILITY = `${namespace}/createavailability`;
export const UPDATEAPPOINTMENTDATA = `${namespace}/updateappointmentdata`;
export const RESETAVAILABILITY = `${namespace}/resetavailability`;
export const REMOVEAVAILABILITYLIST = `${namespace}/removeavailabilitylist`;
export const SENDEMAIL = `${namespace}/sendemail`;
export const RESETWEEKLYHOURS = `${namespace}/resetweeklyhours`;
export const GETCLINICIANAVAILABLEDATES = `${namespace}/getclinicianavailabledates`;