import { handleActions, createAction } from 'redux-actions';
import { defineLoopActions, requestLoopHandlers } from 'libs/state';
import { REQUEST_STATUS } from 'config/constants';

import {
  GETCLIENTDETAILARRAY,
  GETCLINICIANPATIENTPAIRS,
  GETCHATROOMTOKENPAIRS,
  GETCHATDETAILS,
  SETSELECTEDCHATUSERID,
  SETSELECTEDCHATTAB,
  MARKCHANNELUNREAD,
  RESETCHATDETAILSSTATEFIELD,
} from './actionTypes';

const initialState = {
  clinician_patient_pairs: [],
  client_detail_array: [],
  chat_room_token_pairs: [],
  chat_details: [],
  selected_chat_user_id: null,
  selected_chat_tab: 'PA',
  get_clinicianpatientpairs_state: REQUEST_STATUS.INITIAL,
  get_clientdetailarray_state: REQUEST_STATUS.INITIAL,
  get_chatroomtokenpairs_state: REQUEST_STATUS.INITIAL,
  get_chatdetails_state: REQUEST_STATUS.INITIAL,
  set_markchannelunread_state: REQUEST_STATUS.INITIAL,
};

export const {
  start: getClinicianPatientPairs,
  success: getClinicianPatientPairsSuccess,
  fail: getClinicianPatientPairsFail,
} = defineLoopActions(GETCLINICIANPATIENTPAIRS);

export const {
  start: getClientDetailArray,
  success: getClientDetailArraySuccess,
  fail: getClientDetailArrayFail,
} = defineLoopActions(GETCLIENTDETAILARRAY);

export const {
  start: getChatRoomTokenPairs,
  success: getChatRoomTokenPairsSuccess,
  fail: getChatRoomTokenPairsFail,
} = defineLoopActions(GETCHATROOMTOKENPAIRS);

export const {
  start: getChatDetails,
  success: getChatDetailsSuccess,
  fail: getChatDetailsFail,
} = defineLoopActions(GETCHATDETAILS);

export const {
  start: markChannelUnread,
  success: markChannelUnreadSuccess,
  fail: markChannelUnreadFail,
} = defineLoopActions(MARKCHANNELUNREAD);

export const setSelectedChatUserId = createAction(
  SETSELECTEDCHATUSERID,
);

export const setSelectedChatTab = createAction(
  SETSELECTEDCHATTAB,
);

export const resetChatDetailsStateField = createAction(
  RESETCHATDETAILSSTATEFIELD,
);

export const messageCenterReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: GETCLINICIANPATIENTPAIRS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          clinician_patient_pairs: payload,
          get_clinicianpatientpairs_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clinicianpatientpairs_state',
    }),
    ...requestLoopHandlers({
      action: GETCLIENTDETAILARRAY,
      onSuccess: (state, payload) => {
        return {
          ...state,
          client_detail_array: payload,
          get_clientdetailarray_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clientdetailarray_state',
    }),
    ...requestLoopHandlers({
      action: GETCHATROOMTOKENPAIRS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          chat_room_token_pairs: payload,
          get_chatroomtokenpairs_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_chatroomtokenpairs_state',
    }),
    ...requestLoopHandlers({
      action: GETCHATDETAILS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          chat_details: payload,
          get_chatdetails_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_chatdetails_state',
    }),
    ...requestLoopHandlers({
      action: MARKCHANNELUNREAD,
      onSuccess: (state, payload) => {
        return {
          ...state,
          set_markchannelunread_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'set_markchannelunread_state',
    }),
    [SETSELECTEDCHATUSERID]: (state, { payload }) => {
      return {
        ...state,
        selected_chat_user_id: payload,
      };
    },
    [SETSELECTEDCHATTAB]: (state, { payload }) => {
      return {
        ...state,
        selected_chat_tab: payload,
      };
    },
    [RESETCHATDETAILSSTATEFIELD]: (state, { payload }) => {
      return {
        ...state,
        chat_details: [],
      };
    },
  },
  initialState,
);
