/*
 * combines all th existing reducers
 */

import { combineReducers } from 'redux';
import { authReducer } from 'views/auth/reducer';
import { onboardingReducer } from 'views/onboarding/reducer';
import { calendarReducer } from 'views/dashboard/Tabs/Calendar/reducer';
import { messageCenterReducer } from 'views/dashboard/Tabs/MessageCenter/reducer';
import { liveSessionReducer } from 'views/LiveSession/reducer';
import { clientReducer } from 'views/dashboard/Tabs/Clients/reducer';
import { notificationsReducer } from 'views/dashboard/Tabs/Notifications/reducer';
import { reportsReducer } from 'views/dashboard/Tabs/Reports/reducer';
import { billingReducer } from 'views/dashboard/Tabs/Billing/reducer';
import { timeSheetsReducer } from 'views/dashboard/Tabs/TimeSheets/reducer';

const appReducer = combineReducers({
  auth: authReducer,
  onboarding: onboardingReducer,
  calendar: calendarReducer,
  messageCenter: messageCenterReducer,
  liveSession: liveSessionReducer,
  clients: clientReducer,
  notifications: notificationsReducer,
  reports: reportsReducer,
  billing: billingReducer,
  timeSheets: timeSheetsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'talkdoc/auth/logoutclinician') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
