import { takeLatest, call, put } from 'redux-saga/effects';
import { getToken } from 'libs/token';

import {
  getClientList,
  getClientInfo,
  updateClientInfo,
  getClientAppointments,
  getClientSurveys,
  getClientSurveyResponses,
  getCareCoordinatorStatus,
  updateCareCoordinatorStatus,
  getCareTeamEntries,
  createOrUpdateCareTeamEntry,
  deleteCareTeamEntry,
  getEligibilityCheckEntries,
  createOrUpdateEligibilityCheckEntry,
  deleteEligibilityCheckEntry,
  getConsentDocuments,
  getClientSearchOptions,
  fetchHealthPlans,
  getClientTasks,
  sendClientReminder,
  createPatient,
  sendAppointmentPaymentLink,
  getUploadedMedicaidIdUrl,
  downloadConsentDocument,
} from 'api/methods/clients';

import { createNewNote, getNotesList, updateNotes } from 'api/methods/notes';

import {
  GETCLIENTLIST,
  GETCLIENTINFOBYID,
  UPDATECLIENTINFO,
  GETCLIENTAPPOINTMENTS,
  GETCLIENTNNOTES,
  GETCLIENTSURVEYS,
  GETCLIENTSURVEYRESPONSES,
  CREATECLIENTNOTE,
  UPDATECLIENTNOTE,
  GETCARECOORDINATORSTATUS,
  UPDATECARECOORDINATORSTATUS,
  GETCARETEAMENTRIES,
  CREATEORUPDATECARETEAMENTRY,
  DELETECARETEAMENTRY,
  GETELIGIBILITYCHECKENTRIES,
  CREATEORUPDATEELIGIBILITYCHECKENTRY,
  DELETEELIGIBILITYCHECKENTRY,
  GETCONSENTDOCUMENTS,
  GETCLIENTSEARCHOPTIONS,
  FETCHHEALTHPLANS,
  GETCLIENTTASKS,
  SENDCLIENTREMINDER,
  CREATEPATIENT,
  SENDAPPOINTMENTPAYMENTLINK,
  GETUPLOADEDMEDICAIDIDURL,
  DOWNLOADCONSENTDOCUMENT,
} from './actionTypes';

import {
  getClientListSuccess,
  getClientListFail,
  getClientInfoSuccess,
  getClientInfoFail,
  updateClientInfoFail,
  updateClientInfoSuccess,
  getClientAppointmentsSuccess,
  getClientAppointmentsFail,
  getClientNotesSuccess,
  getClientNotesFail,
  getClientSurveysSuccess,
  getClientSurveysFail,
  getClientSurveyResponsesSuccess,
  getClientSurveyResponsesFail,
  createClientNoteSuccess,
  createClientNoteFail,
  updateClientNoteSuccess,
  updateClientNoteFail,
  getCareCoordinatorStatusSuccess,
  getCareCoordinatorStatusFail,
  updateCareCoordinatorStatusSuccess,
  updateCareCoordinatorStatusFail,
  getCareTeamEntriesSuccess,
  getCareTeamEntriesFail,
  createOrUpdateCareTeamEntrySuccess,
  createOrUpdateCareTeamEntryFail,
  deleteCareTeamEntrySuccess,
  deleteCareTeamEntryFail,
  getEligibilityCheckEntriesSuccess,
  getEligibilityCheckEntriesFail,
  createOrUpdateEligibilityCheckEntryFail,
  createOrUpdateEligibilityCheckEntrySuccess,
  deleteEligibilityCheckEntrySuccess,
  deleteEligibilityCheckEntryFail,
  getConsentDocumentsSuccess,
  getConsentDocumentsFail,
  getClientSearchOptionsSuccess,
  getClientSearchOptionsFail,
  fetchHealthPlansSuccess,
  fetchHealthPlansFail,
  getClientTasksSuccess,
  getClientTasksFail,
  sendClientReminderSuccess,
  sendClientReminderFail,
  createPatientSuccess,
  createPatientFail,
  sendAppointmentPaymentLinkSuccess,
  sendAppointmentPaymentLinkFail,
  getUploadedMedicaidIdUrlSuccess,
  getUploadedMedicaidIdUrlFail,
  downloadConsentDocumentSuccess,
  downloadConsentDocumentFail,
} from './reducer';

function* get_clients_list(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getClientList, action.payload, userToken);
  if (response.status === 200) {
    yield put(getClientListSuccess(response.data));
  } else {
    yield put(getClientListFail(response.data));
  }
}

function* get_client_info(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getClientInfo,
    action.payload.patient_id,
    userToken
  );

  if (response.status === 200) {
    yield put(getClientInfoSuccess(response.data));
  } else {
    yield put(getClientInfoFail(response.data));
  }
}

function* update_client_info(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(updateClientInfo, action.payload, userToken);

  if (response.status === 200) {
    yield put(updateClientInfoSuccess(response.data));
  } else {
    yield put(updateClientInfoFail(response.data));
  }
}

function* get_client_appointments(action) {
  const userToken = `Token ${getToken()}`;
  console.log(action);
  const response = yield call(
    getClientAppointments,
    action.payload.patient_user_id,
    action.payload.view_patient_all,
    userToken
  );
  if (response.status === 200) {
    yield put(getClientAppointmentsSuccess(response.data));
  } else {
    yield put(getClientAppointmentsFail(response.data));
  }
}

function* get_client_notes(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getNotesList,
    action.payload.patient_id,
    userToken
  );
  if (response.status === 200) {
    yield put(getClientNotesSuccess(response.data));
  } else {
    yield put(getClientNotesFail(response.data));
  }
}

function* get_client_surveys(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getClientSurveys, userToken);
  if (response.status === 200) {
    yield put(getClientSurveysSuccess(response.data));
  } else {
    yield put(getClientSurveysFail(response.data));
  }
}

function* get_client_survey_responses(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getClientSurveyResponses, userToken);
  if (response.status === 200) {
    yield put(getClientSurveyResponsesSuccess(response.data));
  } else {
    yield put(getClientSurveyResponsesFail(response.data));
  }
}

function* create_client_note(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    createNewNote,
    action.payload.patient_id,
    action.payload.data,
    userToken
  );
  if (response.status === 200) {
    yield put(createClientNoteSuccess(response.data));
  } else {
    yield put(createClientNoteFail(response.data));
  }
}

function* update_client_note(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    updateNotes,
    action.payload.patient_id,
    action.payload.note_id,
    action.payload.data,
    userToken
  );

  if (response.status === 200) {
    yield put(updateClientNoteSuccess(response.data));
  } else {
    yield put(updateClientNoteFail(response.data));
  }
}

function* get_care_coordinator_status(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getCareCoordinatorStatus, userToken);
  if (response.status === 200) {
    yield put(getCareCoordinatorStatusSuccess(response.data));
  } else {
    yield put(getCareCoordinatorStatusFail(response.data));
  }
}

function* update_care_coordinator_status(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    updateCareCoordinatorStatus,
    action.payload,
    userToken
  );
  if (response.status === 200) {
    yield put(updateCareCoordinatorStatusSuccess(response.data));
  } else {
    yield put(updateCareCoordinatorStatusFail(response.data));
  }
}

function* get_care_team_entries(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getCareTeamEntries, action.payload, userToken);
  if (response.status === 200) {
    yield put(getCareTeamEntriesSuccess(response.data));
  } else {
    yield put(getCareTeamEntriesFail(response.data));
  }
}

function* create_or_update_care_team_entry(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    createOrUpdateCareTeamEntry,
    action.payload,
    userToken
  );
  if (response.status === 200) {
    yield put(createOrUpdateCareTeamEntrySuccess(response.data));
  } else {
    yield put(createOrUpdateCareTeamEntryFail(response.data));
  }
}

function* delete_care_team_entry(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(deleteCareTeamEntry, action.payload, userToken);
  if (response.status === 200) {
    yield put(deleteCareTeamEntrySuccess(response.data));
  } else {
    yield put(deleteCareTeamEntryFail(response.data));
  }
}

function* get_eligibility_check_entries(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getEligibilityCheckEntries,
    action.payload,
    userToken
  );
  if (response.status === 200) {
    yield put(getEligibilityCheckEntriesSuccess(response.data));
  } else {
    yield put(getEligibilityCheckEntriesFail(response.data));
  }
}

function* create_or_update_eligibility_check_entry(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    createOrUpdateEligibilityCheckEntry,
    action.payload,
    userToken
  );
  if (response.status === 200) {
    yield put(createOrUpdateEligibilityCheckEntrySuccess(response.data));
  } else {
    yield put(createOrUpdateEligibilityCheckEntryFail(response.data));
  }
}

function* delete_eligibility_check_entry(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    deleteEligibilityCheckEntry,
    action.payload,
    userToken
  );
  if (response.status === 200) {
    yield put(deleteEligibilityCheckEntrySuccess(response.data));
  } else {
    yield put(deleteEligibilityCheckEntryFail(response.data));
  }
}

function* get_consent_documents(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getConsentDocuments, action.payload, userToken);
  if (response.status === 200) {
    yield put(getConsentDocumentsSuccess(response.data));
  } else {
    yield put(getConsentDocumentsFail(response.data));
  }
}

function* get_client_search_options(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getClientSearchOptions,
    action.payload,
    userToken
  );
  if (response.status === 200) {
    yield put(getClientSearchOptionsSuccess(response.data));
  } else {
    yield put(getClientSearchOptionsFail(response.data));
  }
}

function* fetch_health_plans(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(fetchHealthPlans, userToken);
  if (response.status === 200) {
    yield put(fetchHealthPlansSuccess(response.data));
  } else {
    yield put(fetchHealthPlansFail(response.data));
  }
}

function* get_client_tasks(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getClientTasks, action.payload, userToken);
  if (response.status === 200) {
    yield put(getClientTasksSuccess(response.data));
  } else {
    yield put(getClientTasksFail(response.data));
  }
}

function* send_client_reminder_message(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(sendClientReminder, action.payload, userToken);
  if (response.status === 200) {
    yield put(sendClientReminderSuccess());
  } else {
    yield put(sendClientReminderFail());
  }
}

function* create_patient(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(createPatient, action.payload, userToken);
  if (response.status === 200) {
    yield put(createPatientSuccess(response.data));
  } else {
    yield put(createPatientFail());
  }
}

function* send_appointment_payment_link(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    sendAppointmentPaymentLink,
    action.payload,
    userToken
  );
  if (response.status === 200) {
    yield put(sendAppointmentPaymentLinkSuccess());
  } else {
    yield put(sendAppointmentPaymentLinkFail());
  }
}

function* get_uploaded_medicaid_id_url(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getUploadedMedicaidIdUrl,
    action.payload,
    userToken
  );
  if (response.status === 200) {
    yield put(getUploadedMedicaidIdUrlSuccess(response.data));
  } else {
    yield put(getUploadedMedicaidIdUrlFail());
  }
}

function* download_consent_document(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    downloadConsentDocument,
    action.payload,
    userToken
  );
  if (response.status === 200) {
    yield put(downloadConsentDocumentSuccess(response.data));
  } else {
    yield put(downloadConsentDocumentFail());
  }
}

export const saga = function*() {
  yield takeLatest(GETCLIENTLIST, get_clients_list);
  yield takeLatest(GETCLIENTINFOBYID, get_client_info);
  yield takeLatest(UPDATECLIENTINFO, update_client_info);
  yield takeLatest(GETCLIENTAPPOINTMENTS, get_client_appointments);
  yield takeLatest(GETCLIENTNNOTES, get_client_notes);
  yield takeLatest(GETCLIENTSURVEYS, get_client_surveys);
  yield takeLatest(GETCLIENTSURVEYRESPONSES, get_client_survey_responses);
  yield takeLatest(CREATECLIENTNOTE, create_client_note);
  yield takeLatest(UPDATECLIENTNOTE, update_client_note);
  yield takeLatest(GETCARECOORDINATORSTATUS, get_care_coordinator_status);
  yield takeLatest(UPDATECARECOORDINATORSTATUS, update_care_coordinator_status);
  yield takeLatest(GETCARETEAMENTRIES, get_care_team_entries);
  yield takeLatest(
    CREATEORUPDATECARETEAMENTRY,
    create_or_update_care_team_entry
  );
  yield takeLatest(DELETECARETEAMENTRY, delete_care_team_entry);
  yield takeLatest(GETELIGIBILITYCHECKENTRIES, get_eligibility_check_entries);
  yield takeLatest(
    CREATEORUPDATEELIGIBILITYCHECKENTRY,
    create_or_update_eligibility_check_entry
  );
  yield takeLatest(DELETEELIGIBILITYCHECKENTRY, delete_eligibility_check_entry);
  yield takeLatest(GETCONSENTDOCUMENTS, get_consent_documents);
  yield takeLatest(GETCLIENTSEARCHOPTIONS, get_client_search_options);
  yield takeLatest(FETCHHEALTHPLANS, fetch_health_plans);
  yield takeLatest(GETCLIENTTASKS, get_client_tasks);
  yield takeLatest(SENDCLIENTREMINDER, send_client_reminder_message);
  yield takeLatest(CREATEPATIENT, create_patient);
  yield takeLatest(SENDAPPOINTMENTPAYMENTLINK, send_appointment_payment_link);
  yield takeLatest(GETUPLOADEDMEDICAIDIDURL, get_uploaded_medicaid_id_url);
  yield takeLatest(DOWNLOADCONSENTDOCUMENT, download_consent_document);
};
