import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { IconSettings, Toast, ToastContainer } from '@salesforce/design-system-react';
import { hasSucceeded } from 'libs/state';
import { websocketURL } from 'config/env';
import { addRecentNotification } from 'views/dashboard/Tabs/Notifications/reducer';
import { syncAppointments } from 'views/dashboard/Tabs/Calendar/reducer';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import AppRoutes from 'Routes/AppRoutes';
import AuthRoutes from 'Routes/AuthRoutes';
import { MFA_LOGIN_ENABLED } from 'config/constants';
import moment from 'moment';

import './App.scss';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const messageHistory = useRef([]);
  messageHistory.current = useMemo(
    () => messageHistory.current.concat(lastMessage),
    [lastMessage]
  );
  const isMfaVerified = hasSucceeded(useSelector(state => state.auth.verify_mfa_code_state)) || !JSON.parse(MFA_LOGIN_ENABLED);

  const [socketUrl, setSocketUrl] = useState(`${websocketURL}${token}`);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorToastText, setErrorToastText] = useState('');

  const { lastMessage, readyState } = useWebSocket(socketUrl);

  useEffect(() => {
    setSocketUrl(`${websocketURL}${token}`);
  }, [token]);

  useEffect(() => {
    if (lastMessage !== null) {
      var websocket_msg = JSON.parse(lastMessage.data)
      if(websocket_msg.event == 'recent_notifications'){
        dispatch(
          addRecentNotification({
            payload: JSON.parse(lastMessage.data).data,
          })
        );
      } else if(websocket_msg.event == 'appointment_updates'){
        dispatch(
          syncAppointments({
            payload: JSON.parse(lastMessage.data).data,
          })
        );
      }
    }
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  // console.log(`The WebSocket is currently ${connectionStatus}`);

  const errorAction = path => {
    localStorage.removeItem('persist:root');
    window.location.assign(window.location.origin + path);
  };

  axios.interceptors.response.use(undefined, error => {
    if (error.response.status === 401 && window.location.pathname !== '/login') {
      errorAction('/401');
    }
    if (error.response.status === 404) {
      window.location.assign(window.location.origin + '/404');
    }

    if ((error.response.status === 400 &&
      ['/reset-password/', '/signup/'].some(path => window.location.pathname.startsWith(path))) ||
      (error.response.status === 403 && window.location.pathname === '/login')) {
      setShowErrorToast(true);
      setErrorToastText(error.response.data.error);
    }

    return Promise.reject(error);
  });

  const expiry = localStorage.getItem('expiry');
  if (expiry !== null) {
    if (
      moment(expiry).isBefore() &&
      AppRoutes.find(route => window.location.pathname.startsWith(route.path))
        .private
    ) {
      localStorage.setItem('tokenExpired', true);
      errorAction('/login');
    }
  }

  return (
    <>
      <React.Suspense fallback={loading()}>
        <IconSettings iconPath="/icons">
          {showErrorToast &&
            <ToastContainer>
              <Toast
                labels={{ heading: errorToastText }}
                variant="error"
                duration={10000}
                onRequestClose={() => setShowErrorToast(false)}
              />
            </ToastContainer>
          }
          <Switch>
            {AppRoutes.map((prop, key) => {
              if (prop.redirect) {
                return <Redirect from={prop.path} to="/dashboard" key={key} />;
              }
              return (
                <AuthRoutes
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  private={prop.private}
                  name={prop.name}
                  loggedIn={
                    token !== undefined &&
                    (expiry === null || moment(expiry).isAfter())
                  }
                  isMfaVerified={isMfaVerified}
                />
              );
            })}
          </Switch>
        </IconSettings>
      </React.Suspense>
    </>
  );
};

export default App;
