import { takeLatest, call, put } from 'redux-saga/effects';
import { getToken } from 'libs/token';
import { createNewNote, getNotesList, updateNotes } from 'api/methods/notes';
import {
  completeAppointment,
  createAppointmentRoom,
  updatePatientMetrics,
} from '../../api/methods/liveSession';
import {
  CREATENOTEBYTYPE,
  GETNOTESLIST,
  UPDATENOTEBYTYPE,
  COMPLETEAPPOINTMENT,
  UPDATEPATIENTMETRICS,
  CREATEAPPOINTMENTROOM,
} from './actionTypes';
import {
  createNoteSuccess,
  createNoteFail,
  getNotesListSuccess,
  getNotesListFail,
  updateNoteSuccess,
  updateNoteFail,
  completeAppointmentSuccess,
  completeAppointmentFail,
  updatePatientMetricsSuccess,
  updatePatientMetricsFail,
  createAppointmentRoomSuccess,
  createAppointmentRoomFail,
} from './reducer';

function* create_note(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    createNewNote,
    action.payload.patient_id,
    action.payload.data,
    userToken
  );
  if (response.status === 200) {
    yield put(createNoteSuccess(response.data));
  } else {
    yield put(createNoteFail(response.data));
  }
}

function* update_note(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    updateNotes,
    action.payload.patient_id,
    action.payload.note_id,
    action.payload.data,
    userToken
  );

  if (response.status === 200) {
    yield put(updateNoteSuccess(response.data));
  } else {
    yield put(updateNoteFail(response.data));
  }
}

function* get_notes(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getNotesList,
    action.payload.patient_id,
    userToken
  );
  if (response.status === 200) {
    // console.log(response.data, action.payload.appointment_id);
    yield put(getNotesListSuccess(response.data));
  } else {
    yield put(getNotesListFail(response.data));
  }
}

function* mark_complete_appointment(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    completeAppointment,
    action.payload.appointment_id,
    action.payload.data,
    userToken
  );
  if (response.status === 200) {
    yield put(completeAppointmentSuccess(response.data));
  } else {
    yield put(completeAppointmentFail(response.data));
  }
}

function* update_patient_metrics(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(updatePatientMetrics, action.payload, userToken);
  if (response.status === 200) {
    yield put(updatePatientMetricsSuccess(response.data));
  } else {
    yield put(updatePatientMetricsFail(response.data));
  }
}

function* create_appointment_room(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(createAppointmentRoom, action.payload, userToken);
  if (response.status === 200) {
    yield put(createAppointmentRoomSuccess(response.data));
  } else {
    yield put(createAppointmentRoomFail(response.data));
  }
}

export const saga = function*() {
  yield takeLatest(CREATENOTEBYTYPE, create_note);
  yield takeLatest(UPDATENOTEBYTYPE, update_note);
  yield takeLatest(GETNOTESLIST, get_notes);
  yield takeLatest(COMPLETEAPPOINTMENT, mark_complete_appointment);
  yield takeLatest(UPDATEPATIENTMETRICS, update_patient_metrics);
  yield takeLatest(CREATEAPPOINTMENTROOM, create_appointment_room);
};
