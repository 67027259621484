import { takeLatest, call, put } from 'redux-saga/effects';
import { getToken } from 'libs/token';
import { getBillingEntries, editBillingEntry, createBillingEntry, getAppointmentCodes } from 'api/methods/billing';
import { GETBILLINGENTRIES, EDITBILLINGENTRY, CREATEBILLINGENTRY, GETAPPOINTMENTCODES } from './actionTypes';
import {
  getBillingEntriesSuccess,
  getBillingEntriesFail,
  editBillingEntrySuccess,
  editBillingEntriyFail,
  createBillingEntrySuccess,
  createBillingEntriyFail,
  getAppointmentCodesSuccess,
  getAppointmentCodesFail,
} from './reducer';

function* get_billing_entries(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getBillingEntries, action.payload, userToken);
  if (response.status === 200) {
    yield put(getBillingEntriesSuccess(response.data));
  } else {
    yield put(getBillingEntriesFail(response.data));
  }
}

function* edit_billing_entry(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(editBillingEntry, action.payload, userToken);
  if (response.status === 200) {
    yield put(editBillingEntrySuccess(response.data));
  } else {
    yield put(editBillingEntriyFail(response.data));
  }
}

function* create_billing_entry(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(createBillingEntry, action.payload, userToken);
  if (response.status === 200) {
    yield put(createBillingEntrySuccess(response.data));
  } else {
    yield put(createBillingEntriyFail(response.data));
  }
}

function* get_appointment_codes(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getAppointmentCodes, action.payload, userToken);
  if (response.status === 200) {
    yield put(getAppointmentCodesSuccess(response.data));
  } else {
    yield put(getAppointmentCodesFail(response.data));
  }
}

export const saga = function* () {
  yield takeLatest(GETBILLINGENTRIES, get_billing_entries);
  yield takeLatest(EDITBILLINGENTRY, edit_billing_entry);
  yield takeLatest(CREATEBILLINGENTRY, create_billing_entry);
  yield takeLatest(GETAPPOINTMENTCODES, get_appointment_codes);
};
