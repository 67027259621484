import { handleActions, createAction } from 'redux-actions';
import { defineLoopActions, requestLoopHandlers } from 'libs/state';
import { REQUEST_STATUS } from 'config/constants';

import {
  GETAPPOINTMENTSLIST,
  UPDATEAPPOINTMENT,
  DECLINEAPPOINTMENT,
  GETNEXTAPPOINTMENTDETAIL,
  CREATEAPPOINTMENT,
  GETCLINICIANLIST,
  GETAVAILABILITYLISTBYID,
  GETCLINICIANTIMESLOTS,
  GETAPPOINTMENTBYID,
  SYNCAPPOINTMENTS,
  GETAPPOINTMENTDURATIONOPTIONS,
} from './actionTypes';

const initialState = {
  new_appointment_detail: {},
  appointments_list: [],
  clinicians_list: [],
  availability_list_byid: [],
  next_appointment_detail: null,
  clinician_time_slots: [],
  appointment_by_id: {},
  appointment_durations: [],
  allow_custom_appointment_durations: true,
  get_cliniciantimeslots_state: REQUEST_STATUS.INITIAL,
  get_appointmentslist_state: REQUEST_STATUS.INITIAL,
  update_appointment_state: REQUEST_STATUS.INITIAL,
  decline_appointment_state: REQUEST_STATUS.INITIAL,
  get_nextappointmentdetail_state: REQUEST_STATUS.INITIAL,
  create_appointment_state: REQUEST_STATUS.INITIAL,
  get_clinicianlist_state: REQUEST_STATUS.INITIAL,
  get_availabilitylistbyid_state: REQUEST_STATUS.INITIAL,
  get_appointment_by_id_state: REQUEST_STATUS.INITIAL,
  get_appointment_duration_options: REQUEST_STATUS.INITIAL
};

export const syncAppointments = createAction(SYNCAPPOINTMENTS);

export const {
  start: getAppointmentsList,
  success: getAppointmentsListSuccess,
  fail: getAppointmentsListFail,
} = defineLoopActions(GETAPPOINTMENTSLIST);

export const {
  start: updateAppointment,
  success: updateAppointmentSuccess,
  fail: updateAppointmentFail,
} = defineLoopActions(UPDATEAPPOINTMENT);

export const {
  start: getAppointmentById,
  success: getAppointmentByIdSuccess,
  fail: getAppointmentByIdFail,
} = defineLoopActions(GETAPPOINTMENTBYID);

export const {
  start: declineAppointment,
  success: declineAppointmentSuccess,
  fail: declineAppointmentFail,
} = defineLoopActions(DECLINEAPPOINTMENT);

export const {
  start: getNextAppointmentDetail,
  success: getNextAppointmentDetailSuccess,
  fail: getNextAppointmentDetailFail,
} = defineLoopActions(GETNEXTAPPOINTMENTDETAIL);

export const {
  start: createAppointment,
  success: createAppointmentSuccess,
  fail: createAppointmentFail,
} = defineLoopActions(CREATEAPPOINTMENT);

export const {
  start: getClinicianList,
  success: getClinicianListSuccess,
  fail: getClinicianListFail,
} = defineLoopActions(GETCLINICIANLIST);

export const {
  start: getAvailabilityListById,
  success: getAvailabilityListByIdSuccess,
  fail: getAvailabilityListByIdFail,
} = defineLoopActions(GETAVAILABILITYLISTBYID);

export const {
  start: getClinicianTimeSlots,
  success: getClinicianTimeSlotsSuccess,
  fail: getClinicianTimeSlotsFail,
} = defineLoopActions(GETCLINICIANTIMESLOTS);

export const {
  start: getAppointmentDurationOptions,
  success: getAppointmentDurationOptionsSuccess,
  fail: getAppointmentDurationOptionsFail,
} = defineLoopActions(GETAPPOINTMENTDURATIONOPTIONS);

export const calendarReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: GETAPPOINTMENTSLIST,
      onSuccess: (state, payload) => {
        return {
          ...state,
          appointments_list: payload,
          get_appointmentslist_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_appointmentslist_state',
    }),

    ...requestLoopHandlers({
      action: UPDATEAPPOINTMENT,
      onSuccess: (state, payload) => {
        return {
          ...state,
          appointments_list: state.appointments_list.map(item => {
            if (item.appointment_id === payload.appointment_id) {
              return payload;
            } else {
              return item;
            }
          }),
          update_appointment_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: (state) => {
        return {
          ...state,
          update_appointment_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'update_appointment_state',
    }),

    ...requestLoopHandlers({
      action: GETAPPOINTMENTBYID,
      onSuccess: (state, payload) => {
        return {
          ...state,
          appointment_by_id: payload,
          get_appointment_by_id_state: REQUEST_STATUS.SUCCESS,
          appointments_list: [...state.appointments_list, payload],
        };
      },
      stateField: 'get_appointment_by_id_state',
    }),

    ...requestLoopHandlers({
      action: DECLINEAPPOINTMENT,
      onSuccess: (state, payload) => {
        return {
          ...state,
          appointments_list: state.appointments_list.map(item => {
            if (item.appointment_id === payload.appointment_id) {
              return payload;
            } else {
              return item;
            }
          }),
          decline_appointment_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'decline_appointment_state',
    }),

    ...requestLoopHandlers({
      action: GETNEXTAPPOINTMENTDETAIL,
      onSuccess: (state, payload) => {
        return {
          ...state,
          next_appointment_detail: payload,
          get_nextappointmentdetail_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_nextappointmentdetail_state',
    }),

    ...requestLoopHandlers({
      action: CREATEAPPOINTMENT,
      onSuccess: (state, payload) => {
        return {
          ...state,
          new_appointment_detail: payload,
          appointments_list: [...state.appointments_list, payload],
          create_appointment_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'create_appointment_state',
    }),

    ...requestLoopHandlers({
      action: GETCLINICIANLIST,
      onSuccess: (state, payload) => {
        return {
          ...state,
          clinicians_list: payload,
          get_clinicianlist_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clinicianlist_state',
    }),

    ...requestLoopHandlers({
      action: GETAVAILABILITYLISTBYID,
      onSuccess: (state, payload) => {
        return {
          ...state,
          availability_list_byid: payload,
          get_availabilitylistbyid_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_availabilitylistbyid_state',
    }),

    ...requestLoopHandlers({
      action: GETCLINICIANTIMESLOTS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          clinician_time_slots: payload,
          get_cliniciantimeslots_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_cliniciantimeslots_state',
    }),

    ...requestLoopHandlers({
      action: GETAPPOINTMENTDURATIONOPTIONS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          appointment_durations: payload['durations'],
          allow_custom_appointment_durations: payload['allow_custom_appointment_durations'],
          get_appointment_duration_options: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_appointment_duration_options'
    }),

    [SYNCAPPOINTMENTS]: (state, { payload }) => {
      if (payload.payload.change_type == "CREATE") {
        return {
          ...state,
          appointments_list: [...state.appointments_list, payload.payload.appointment_data],
        };
      } else if (payload.payload.change_type == "UPDATE") {
        return {
          ...state,
          appointments_list: state.appointments_list.map(item => {
            if (item.appointment_id === payload.payload.appointment_data.appointment_id) {
              return payload.payload.appointment_data;
            } else {
              return item;
            }
          }),
        };
      }
    },

  },
  initialState
);
