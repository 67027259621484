import { takeLatest, call, put } from 'redux-saga/effects';
import { getToken } from 'libs/token';

import {
  getClinicianDetail,
  updateClinicianDetail,
  getAvailabilityList,
  createAvailability,
  updateAppointmentData,
  resetAvailability,
  resetWeeklyhours,
  getClinicianAvailableDates,
} from 'api/methods/onboarding';
import { sendEmail } from 'api/methods/emails';
import {
  GETCLINICIANDETAIL,
  UPDATECLINICIANDETAIL,
  GETAVAILABILITYLIST,
  CREATEAVAILABILITY,
  UPDATEAPPOINTMENTDATA,
  RESETAVAILABILITY,
  SENDEMAIL,
  RESETWEEKLYHOURS,
  GETCLINICIANAVAILABLEDATES,
} from './actionTypes';
import {
  getClinicianDetailSuccess,
  getClinicianDetailFail,
  updateClinicianDetailSuccess,
  updateClinicianDetailFail,
  getAvailabilityListSuccess,
  getAvailabilityListFail,
  createAvailabilitySuccess,
  createAvailabilityFail,
  updateAppointmentDataSuccess,
  updateAppointmentDataFail,
  resetAvailabilitySuccess,
  resetAvailabilityFail,
  sendEmailSuccess,
  sendEmailFail,
  resetWeeklyhoursSuccess,
  resetWeeklyhoursFail,
  getClinicianAvailableDatesSuccess,
  getClinicianAvailableDatesFail,
} from './reducer';
import moment from 'moment-timezone';

function* get_clinician_detail(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getClinicianDetail, action.payload.id, userToken);
  if (response.status === 200) {
    yield put(getClinicianDetailSuccess(response.data));
  } else {
    yield put(getClinicianDetailFail(response.data));
  }
}

function* update_clinician_detail(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    updateClinicianDetail,
    action.payload.id,
    action.payload.data,
    userToken
  );
  if (response.status === 200) {
    yield put(updateClinicianDetailSuccess(response.data));
  } else {
    yield put(updateClinicianDetailFail(response.data));
  }
}

function* get_availability_list(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getAvailabilityList,
    action.payload.filter,
    userToken
  );
  if (response.status === 200) {
    const convertLocalTime = response.data.map(item => {
      return {
        ...item,
        start_datetime: moment(item.start_datetime)
          .local()
          .format(),
        end_datetime: moment(item.end_datetime)
          .local()
          .format(),
      };
    });

    yield put(getAvailabilityListSuccess(convertLocalTime));
  } else {
    yield put(getAvailabilityListFail(response.data));
  }
}

function* create_availability(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(createAvailability, action.payload, userToken);

  if (response.status === 200) {
    const responselist = yield call(
      getAvailabilityList,
      action.payload.clinician_id ? `?clinician_id=${action.payload.clinician_id}` : '',
      userToken
    );
    if (responselist.status === 200) {
      yield put(createAvailabilitySuccess(responselist.data));
    }
  } else {
    yield put(createAvailabilityFail(response.data));
  }
}

function* update_appointmentdata(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    updateAppointmentData,
    action.payload.id,
    action.payload.data,
    userToken
  );
  if (response.status === 200) {
    yield put(updateAppointmentDataSuccess(response.data));
    const resp = yield call(getClinicianDetail, action.payload.id, userToken);
    if (resp.status === 200) {
      yield put(getClinicianDetailSuccess(resp.data));
    } else {
      yield put(getClinicianDetailFail(resp.data));
    }
  } else {
    yield put(updateAppointmentDataFail(response.data));
  }
}

function* reset_availability(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(resetAvailability, userToken);
  if (response.status === 200) {
    yield put(resetAvailabilitySuccess([]));
  } else {
    yield put(resetAvailabilityFail([]));
  }
}

function* send_email(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(sendEmail, action.payload, userToken);
  if (response.status === 200) {
    yield put(sendEmailSuccess(response.data));
  } else {
    yield put(sendEmailFail(response.data));
  }
}

function* reset_weekly_hours(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(resetWeeklyhours, action.payload, userToken);
  if (response.status === 200) {
    yield put(resetWeeklyhoursSuccess(response.data));
  } else {
    yield put(resetWeeklyhoursFail(response.data));
  }
}

function* get_clinician_available_dates(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getClinicianAvailableDates,
    {
      start_date: moment().format('YYYY-MM-DD'),
      time_zone: moment.tz.guess(),
      patient_user_id: action.payload.patient_user_id,
      clinician_user_id: action.payload.clinician_user_id,
      duration: action.payload.duration
    },
    userToken,
  );

  if (response.status === 200) {
    yield put(getClinicianAvailableDatesSuccess(response.data));
  } else {
    yield put(getClinicianAvailableDatesFail(response.data));
  }
}

export const saga = function* () {
  yield takeLatest(GETCLINICIANDETAIL, get_clinician_detail);
  yield takeLatest(UPDATECLINICIANDETAIL, update_clinician_detail);
  yield takeLatest(GETAVAILABILITYLIST, get_availability_list);
  yield takeLatest(CREATEAVAILABILITY, create_availability);
  yield takeLatest(UPDATEAPPOINTMENTDATA, update_appointmentdata);
  yield takeLatest(RESETAVAILABILITY, reset_availability);
  yield takeLatest(SENDEMAIL, send_email);
  yield takeLatest(RESETWEEKLYHOURS, reset_weekly_hours);
  yield takeLatest(GETCLINICIANAVAILABLEDATES, get_clinician_available_dates);
};
