import { takeLatest, call, put } from 'redux-saga/effects';
import { setToken, getToken } from 'libs/token';
import { history } from 'store/history';

import {
  registerClinician,
  loginClinician,
  logoutClinician,
  getClinicianInfo,
  verifyActivationToken,
  sendClinicianMfaCode,
  verifyClinicianMfaCode,
  requestPasswordReset,
  verifyResetPasswordToken,
  resetPassword,
  requestPatientActivationLink,
} from 'api/methods/auth';

import { getClinicianDetail } from 'api/methods/onboarding';

import {
  VERIFYACTIVATIONTOKEN,
  REGISTERCLINICIAN,
  LOGINCLINICIAN,
  LOGOUTCLINICIAN,
  SENDMFACODE,
  VERIFYMFACODE,
  REQUESTPASSWORDRESET,
  VERIFYRESETPASSWORDTOKEN,
  RESETPASSWORD,
  REQUESTPATIENTACTIVATIONLINK,
} from './actionTypes';

import {
  verifyTokenSuccess,
  verifyTokenFail,
  registerClinicianSuccess,
  registerClinicianFail,
  loginClinicianSuccess,
  loginClinicianFail,
  logoutClinicianSuccess,
  logoutClinicianFail,
  getClinicianProfile,
  sendMfaCode,
  sendMfaCodeSuccess,
  sendMfaCodeFail,
  verifyMfaCodeSuccess,
  verifyMfaCodeFail,
  requestPasswordResetSuccess,
  requestPasswordResetFail,
  verifyResetPasswordTokenSuccess,
  verifyResetPasswordTokenFail,
  resetPasswordSuccess,
  resetPasswordFail,
  requestPatientActivationLinkSuccess,
  requestPatientActivationLinkFail,
} from './reducer';

import {
  getClinicianDetailSuccess,
  getClinicianDetailFail,
} from '../onboarding/reducer';

import { MFA_LOGIN_ENABLED } from 'config/constants';

function* reset_password(action) {
  const response = yield call(resetPassword, action.payload);
  if (response.status === 201) {
    yield put(resetPasswordSuccess(response.data));
  } else {
    yield put(resetPasswordFail(response.data));
  }
}

function* verify_token(action) {
  const response = yield call(verifyActivationToken, action.payload);
  if (response.status === 200) {
    yield put(verifyTokenSuccess(response.data));
  } else {
    yield put(verifyTokenFail(response.data));
  }
}

function* verify_resetpassword_token(action) {
  const response = yield call(verifyResetPasswordToken, action.payload);
  if (response.status === 200) {
    yield put(verifyResetPasswordTokenSuccess(response.data));
  } else {
    yield put(verifyResetPasswordTokenFail(response.data));
  }
}

function* register_clinician(action) {
  const response = yield call(registerClinician, action.payload);
  if (response.status === 200) {
    yield call(setToken, response.data.token);
    yield put(registerClinicianSuccess(response.data));
  } else {
    yield put(registerClinicianFail(response.data));
  }
}

function* login_clinician(action) {
  const response = yield call(
    loginClinician,
    action.payload.data,
    action.payload.token
  );
  if (response.status === 200) {
    const userToken = `Token ${response.data.token}`;
    if (response.data.user.type == 'PA') {
      // Logout and redirect to client website
      yield call(logoutClinician, userToken);
      const redirectUrl = window.location.href.replace('clinician', 'client');
      window.location.replace(redirectUrl);
    } else {
      yield call(setToken, response.data.token);
      yield put(loginClinicianSuccess(response.data));
      if (JSON.parse(MFA_LOGIN_ENABLED)) {
        yield put(sendMfaCode());
        history.push('/confirmlogin');
      } else {
        yield complete_login(
          response.data.user.type,
          response.data.user.clinician_id,
          userToken
        );
      }
    }
  } else {
    yield put(loginClinicianFail(response.data));
  }
}

function* send_mfa_code(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(sendClinicianMfaCode, userToken);
  if (response.status === 200) {
    yield put(sendMfaCodeSuccess(response.data));
  } else {
    yield put(sendMfaCodeFail(response.data));
  }
}

function* verify_mfa_code(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    verifyClinicianMfaCode,
    action.payload,
    userToken
  );
  const type = action.payload.type;
  if (response.status === 200) {
    yield put(verifyMfaCodeSuccess(response.data));
    yield complete_login(type, action.payload.clinician_id, userToken);
  } else {
    yield put(verifyMfaCodeFail(response.data));
  }
}

function* complete_login(type, clinician_id, userToken) {
  if (type === 'CA' || type === 'ST') {
    ////////// Care coordinator or Staff /////////
    history.push('/dashboard');
  } else if (type === 'CL') {
    ////////// Clinician //////////
    const clinician_detail = yield call(
      getClinicianDetail,
      clinician_id,
      userToken
    );
    if (clinician_detail.status === 200) {
      yield put(getClinicianDetailSuccess(clinician_detail.data));
      if (
        clinician_detail.data.profile_completed &&
        clinician_detail.data.availability_completed
      ) {
        history.push('/dashboard');
      } else {
        history.push('/onboarding');
      }
    } else {
      yield put(getClinicianDetailFail(clinician_detail.data));
    }
  }
}

function* logout_clinician(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(logoutClinician, userToken);
  if (response.status === 204) {
    yield put(logoutClinicianSuccess(response.data));
  } else {
    yield put(logoutClinicianFail(response.data));
  }
}

function* request_password_reset(action) {
  const response = yield call(requestPasswordReset, action.payload);
  if (response.status === 200) {
    yield put(requestPasswordResetSuccess(response.data));
  } else {
    yield put(requestPasswordResetFail(response.data));
  }
}

function* request_patient_activation_link(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(requestPatientActivationLink, action.payload, userToken);
  if (response.status === 200) {
    yield put(requestPatientActivationLinkSuccess());
  } else {
    yield put(requestPatientActivationLinkFail());
  }
}

export const saga = function* () {
  yield takeLatest(REGISTERCLINICIAN, register_clinician);
  yield takeLatest(LOGINCLINICIAN, login_clinician);
  yield takeLatest(LOGOUTCLINICIAN, logout_clinician);
  yield takeLatest(VERIFYACTIVATIONTOKEN, verify_token);
  yield takeLatest(SENDMFACODE, send_mfa_code);
  yield takeLatest(VERIFYMFACODE, verify_mfa_code);
  yield takeLatest(REQUESTPASSWORDRESET, request_password_reset);
  yield takeLatest(VERIFYRESETPASSWORDTOKEN, verify_resetpassword_token);
  yield takeLatest(RESETPASSWORD, reset_password);
  yield takeLatest(REQUESTPATIENTACTIVATIONLINK, request_patient_activation_link);
};
