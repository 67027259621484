import apiCall from 'libs/apiCall';

export const createNewNote = (patient_id, data, token) =>
  apiCall(`/note/${patient_id}/notes/`, data, 'POST', token);

export const getNotesList = (patient_id, token) =>
  apiCall(`/note/${patient_id}/notes/`, null, 'GET', token);

export const updateNotes = (patient_id, note_id, data, token) =>
  apiCall(`/note/${patient_id}/notes/${note_id}/`, data, 'PUT', token);
