const namespace = 'talkdoc/auth';

export const VERIFYACTIVATIONTOKEN = `${namespace}/verifyactivationtoken`;
export const REGISTERCLINICIAN = `${namespace}/registerclinician`;
export const LOGINCLINICIAN = `${namespace}/loginclinician`;
export const LOGOUTCLINICIAN = `${namespace}/logoutclinician`;
export const REQUESTPASSWORDRESET = `${namespace}/requestpasswordreset`;
export const VERIFYRESETPASSWORDTOKEN = `${namespace}/verifyresetpasswordtoken`;
export const RESETPASSWORD = `${namespace}/resetpassword`;
export const SENDMFACODE = `${namespace}/sendmfacode`;
export const VERIFYMFACODE = `${namespace}/verifymfacode`;
export const SENDMFACODECOUNT = `${namespace}/sendmfacodecount`;
export const VERIFYMFACODECOUNT = `${namespace}/verifymfacodecount`;
export const REQUESTPATIENTACTIVATIONLINK = `${namespace}/requestpatientactivationlink`;
