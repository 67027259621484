import apiCall from 'libs/apiCall';

export const getClientList = (data, token) =>
  apiCall('/patient/getclientlist/', data, 'GET', token);

export const getClientInfo = (patient_id, token) =>
  apiCall(`/patient/patients/${patient_id}/`, null, 'GET', token);

export const updateClientInfo = (data, token) =>
  apiCall('/patient/updatepatientinfo/', data, 'POST', token);

export const getClientAppointments = (
  patient_user_id,
  view_patient_all,
  token
) =>
  apiCall(
    `/appointment/appointments/?patient__user_id=${patient_user_id}&view_patient_all=${view_patient_all}`,
    null,
    'GET',
    token
  );

export const getClientSurveys = token =>
  apiCall(`/survey/surveys/`, null, 'GET', token);

export const getClientSurveyResponses = token =>
  apiCall(`/survey/responses/`, null, 'GET', token);

export const getCareCoordinatorStatus = token =>
  apiCall(`/carecoordinator/status/`, null, 'GET', token);

export const updateCareCoordinatorStatus = (data, token) =>
  apiCall(`/carecoordinator/status/`, data, 'POST', token);

export const getCareTeamEntries = (data, token) =>
  apiCall(`/matchmaker/careteamentry/`, data, 'GET', token);

export const createOrUpdateCareTeamEntry = (data, token) =>
  apiCall(`/matchmaker/careteamentry/`, data, 'POST', token);

export const deleteCareTeamEntry = (data, token) =>
  apiCall(`/matchmaker/careteamentry/`, data, 'DELETE', token);

export const getEligibilityCheckEntries = (data, token) =>
  apiCall(`/eligibility/eligibilitycheckentry/`, data, 'GET', token);

export const createOrUpdateEligibilityCheckEntry = (data, token) =>
  apiCall(`/eligibility/eligibilitycheckentry/`, data, 'POST', token);

export const deleteEligibilityCheckEntry = (data, token) =>
  apiCall(`/eligibility/eligibilitycheckentry/`, data, 'DELETE', token);

export const getConsentDocuments = (data, token) =>
  apiCall(`/patient/consentdocument/`, data, 'GET', token);

export const getClientSearchOptions = (data, token) =>
  apiCall(`/patient/search/`, data, 'GET', token);

export const fetchHealthPlans = token =>
  apiCall('/patient/health-plans/', null, 'GET', token);

export const getClientTasks = (data, token) =>
  apiCall('/patient/clienttasks/', data, 'GET', token);

export const sendClientReminder = (data, token) =>
  apiCall('/patient/sendreminder/', data, 'POST', token);

export const createPatient = (data, token) =>
  apiCall('/patient/createpatient/', data, 'POST', token);

export const sendAppointmentPaymentLink = (data, token) =>
  apiCall(`/appointment/appointment-payment/`, data, 'GET', token);

export const getUploadedMedicaidIdUrl = (data, token) =>
  apiCall(`/eligibility/medicaididupload/`, data, 'GET', token);

export const downloadConsentDocument = (data, token) =>
  apiCall(`/patient/consentdocumentdownload/`, data, 'GET', token);
