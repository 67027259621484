import { handleActions, createAction } from 'redux-actions';
import { defineLoopActions, requestLoopHandlers } from 'libs/state';
import { REQUEST_STATUS } from 'config/constants';

import {
  GETCLINICIANDETAIL,
  UPDATECLINICIANDETAIL,
  GETAVAILABILITYLIST,
  CREATEAVAILABILITY,
  UPDATEAPPOINTMENTDATA,
  RESETAVAILABILITY,
  REMOVEAVAILABILITYLIST,
  SENDEMAIL,
  RESETWEEKLYHOURS,
  GETCLINICIANAVAILABLEDATES,
} from './actionTypes';

const initialState = {
  clinician_detail: {},
  updated_clinician_detail: {},
  availability_list: [],
  clinician_available_dates: [],
  get_clinician_available_dates_state: REQUEST_STATUS.INITIAL,
  get_cliniciandetail_state: REQUEST_STATUS.INITIAL,
  update_cliniciandetail_state: REQUEST_STATUS.INITIAL,
  update_appointmentdata_state: REQUEST_STATUS.INITIAL,
  get_availabilitylist_state: REQUEST_STATUS.INITIAL,
  create_availability_state: REQUEST_STATUS.INITIAL,
  reset_availability_state: RESETAVAILABILITY.INITIAL,
  send_email_state: REQUEST_STATUS.INITIAL,
  reset_weeklyhours_state: REQUEST_STATUS.INITIAL,
};

export const {
  start: getClinicianDetail,
  success: getClinicianDetailSuccess,
  fail: getClinicianDetailFail,
} = defineLoopActions(GETCLINICIANDETAIL);

export const {
  start: updateClinicianDetail,
  success: updateClinicianDetailSuccess,
  fail: updateClinicianDetailFail,
} = defineLoopActions(UPDATECLINICIANDETAIL);

export const {
  start: getAvailabilityList,
  success: getAvailabilityListSuccess,
  fail: getAvailabilityListFail,
} = defineLoopActions(GETAVAILABILITYLIST);

export const {
  start: getClinicianAvailableDates,
  success: getClinicianAvailableDatesSuccess,
  fail: getClinicianAvailableDatesFail,
} = defineLoopActions(GETCLINICIANAVAILABLEDATES);

export const {
  start: createAvailability,
  success: createAvailabilitySuccess,
  fail: createAvailabilityFail,
} = defineLoopActions(CREATEAVAILABILITY);

export const {
  start: updateAppointmentData,
  success: updateAppointmentDataSuccess,
  fail: updateAppointmentDataFail,
} = defineLoopActions(UPDATEAPPOINTMENTDATA);

export const {
  start: resetAvailability,
  success: resetAvailabilitySuccess,
  fail: resetAvailabilityFail,
} = defineLoopActions(RESETAVAILABILITY);

export const {
  start: resetWeeklyhours,
  success: resetWeeklyhoursSuccess,
  fail: resetWeeklyhoursFail,
} = defineLoopActions(RESETWEEKLYHOURS);

export const removeAvailabilityList = createAction(REMOVEAVAILABILITYLIST);

export const {
  start: sendEmail,
  success: sendEmailSuccess,
  fail: sendEmailFail,
} = defineLoopActions(SENDEMAIL);

export const onboardingReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: GETCLINICIANDETAIL,
      onSuccess: (state, payload) => {
        return {
          ...state,
          clinician_detail: payload,
          get_cliniciandetail_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_cliniciandetail_state',
    }),

    ...requestLoopHandlers({
      action: UPDATECLINICIANDETAIL,
      onSuccess: (state, payload) => {
        return {
          ...state,
          clinician_detail: payload,
          update_cliniciandetail_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'update_cliniciandetail_state',
    }),

    ...requestLoopHandlers({
      action: GETAVAILABILITYLIST,
      onSuccess: (state, payload) => {
        return {
          ...state,
          availability_list: payload,
          get_availabilitylist_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_availabilitylist_state',
    }),

    ...requestLoopHandlers({
      action: GETCLINICIANAVAILABLEDATES,
      onSuccess: (state, payload) => {
        return {
          ...state,
          clinician_available_dates: payload['dates'],
          get_clinician_available_dates_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_clinician_available_dates_state',
    }),

    ...requestLoopHandlers({
      action: CREATEAVAILABILITY,
      onSuccess: (state, payload) => {
        return {
          ...state,
          availability_list: payload,
          create_availability_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'create_availability_state',
    }),

    ...requestLoopHandlers({
      action: RESETAVAILABILITY,
      onSuccess: (state, payload) => {
        return {
          ...state,
          availability_list: payload,
          reset_availability_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'reset_availability_state',
    }),

    ...requestLoopHandlers({
      action: UPDATEAPPOINTMENTDATA,
      onSuccess: (state, payload) => {
        return {
          ...state,
          clinician_detail: {
            ...state.clinician_detail,
            buffer: payload.updated.buffer,
            appointment_range: payload.updated.appointment_range,
          },
          availability_list: payload.availabilities,
          update_appointmentdata_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'update_appointmentdata_state',
    }),

    [REMOVEAVAILABILITYLIST]: (state, { payload }) => {
      return {
        ...state,
        availability_list: [],
      };
    },

    ...requestLoopHandlers({
      action: SENDEMAIL,
      onSuccess: (state, payload) => {
        return {
          ...state,
          send_email_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'send_email_state',
    }),

    ...requestLoopHandlers({
      action: RESETWEEKLYHOURS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          availability_list: payload,
          reset_weeklyhours_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'reset_weeklyhours_state',
    }),
  },
  initialState
);
