import { handleActions } from 'redux-actions';
import { defineLoopActions, requestLoopHandlers } from 'libs/state';
import { REQUEST_STATUS } from 'config/constants';
import { GETBILLINGENTRIES, EDITBILLINGENTRY, GETAPPOINTMENTCODES, CREATEBILLINGENTRY } from './actionTypes';

const initialState = {
  billing_entries: [],
  diagnosis_codes: [],
  billing_codes: [],
  get_billing_entries_state: REQUEST_STATUS.INITIAL,
  edit_billing_entry_state: REQUEST_STATUS.INITIAL,
  get_appointment_codes_state: REQUEST_STATUS.INITIAL,
  create_billing_entry_state: REQUEST_STATUS.INITIAL,
};

export const {
  start: getBillingEntries,
  success: getBillingEntriesSuccess,
  fail: getBillingEntriesFail,
} = defineLoopActions(GETBILLINGENTRIES);

export const {
  start: editBillingEntry,
  success: editBillingEntrySuccess,
  fail: editBillingEntriyFail,
} = defineLoopActions(EDITBILLINGENTRY);

export const {
  start: createBillingEntry,
  success: createBillingEntrySuccess,
  fail: createBillingEntriyFail,
} = defineLoopActions(CREATEBILLINGENTRY);

export const {
  start: getAppointmentCodes,
  success: getAppointmentCodesSuccess,
  fail: getAppointmentCodesFail,
} = defineLoopActions(GETAPPOINTMENTCODES);

export const billingReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: GETBILLINGENTRIES,
      onSuccess: (state, payload) => {
        return {
          ...state,
          billing_entries: payload,
          get_billing_entries_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_billing_entries_state',
    }),
    ...requestLoopHandlers({
      action: EDITBILLINGENTRY,
      onSuccess: (state, payload) => {
        return {
          ...state,
          billing_entries: state.billing_entries.map(item => {
            if (item.id === payload.id) {
              if (item.status === payload.status) return payload;
            } else {
              return item;
            }
          }).filter(item => item),
          edit_billing_entry_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'edit_billing_entry_state',
    }),
    ...requestLoopHandlers({
      action: CREATEBILLINGENTRY,
      onSuccess: (state, payload) => {
        return {
          ...state,
          billing_entries: [payload],
          create_billing_entry_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'create_billing_entry_state',
    }),
    ...requestLoopHandlers({
      action: GETAPPOINTMENTCODES,
      onSuccess: (state, payload) => {
        return {
          ...state,
          diagnosis_codes: !payload.length ? []
            : payload[0].code_type === 'DIAG' ? payload.map(item => ({ 'id': item.id, 'subTitle': item.value, 'label': item.description })) : [...state.diagnosis_codes],
          billing_codes: !payload.length ? []
            : payload[0].code_type === 'BILL' ? payload.map(item => ({ 'id': item.id, 'label': item.value })) : [...state.billing_codes],
          get_appointment_codes_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'get_appointment_codes_state',
    }),
  },
  initialState
);
