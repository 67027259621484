import apiCall from 'libs/apiCall';

export const verifyActivationToken = data =>
  apiCall('/auth/activate/', data, 'POST', '');

export const registerClinician = data =>
  apiCall('/auth/register/clinician/', data, 'POST', '');

export const requestPasswordReset = data =>
  apiCall('/auth/reset-password/request/', data, 'POST', '');

export const verifyResetPasswordToken = data =>
  apiCall('/auth/reset-password/verify/', data, 'POST', '');

export const resetPassword = data =>
  apiCall('/auth/reset-password/', data, 'POST', '');

export const loginClinician = (data, authToken) =>
  apiCall('/auth/login/', data, 'POST', authToken, 'auth');

export const logoutClinician = token =>
  apiCall('/auth/logout/', null, 'POST', token, 'auth');

export const getClinicianInfo = token =>
  apiCall('/auth/user/', null, 'GET', token);

export const sendClinicianMfaCode = token =>
  apiCall('/auth/verifyclinicianmfa/', null, 'GET', token);

export const verifyClinicianMfaCode = (data, token) =>
  apiCall('/auth/verifyclinicianmfa/', data, 'POST', token);

export const requestPatientActivationLink = (data, token) =>
  apiCall('/auth/activate/request/', data, 'GET', token);