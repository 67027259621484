/**
 *  Redux saga class init
 * Import every feature saga here
 *
 */
import { all } from 'redux-saga/effects';
import { saga as authSaga } from 'views/auth/sagas';
import { saga as onboardingSaga } from 'views/onboarding/sagas';
import { saga as calendarSaga } from 'views/dashboard/Tabs/Calendar/sagas';
import { saga as messageCenterSaga } from 'views/dashboard/Tabs/MessageCenter/sagas';
import { saga as liveSessionSaga } from 'views/LiveSession/sagas';
import { saga as clientSaga } from 'views/dashboard/Tabs/Clients/sagas';
import { saga as notificationsSaga } from 'views/dashboard/Tabs/Notifications/sagas';
import { saga as reportsSaga } from 'views/dashboard/Tabs/Reports/sagas';
import { saga as billingSaga } from 'views/dashboard/Tabs/Billing/sagas';
import { saga as timeSheetsSaga } from 'views/dashboard/Tabs/TimeSheets/sagas';

export default function* rootSaga() {
  yield all([
    authSaga(),
    onboardingSaga(),
    calendarSaga(),
    messageCenterSaga(),
    liveSessionSaga(),
    clientSaga(),
    notificationsSaga(),
    reportsSaga(),
    billingSaga(),
    timeSheetsSaga()
  ]);
}
