export const API_BASE_URL_VERSIONED =
  process.env.REACT_APP_BACKEND_HTTP_URL || 'http://localhost:8000/api';

export const websocketURL =
  process.env.REACT_APP_BACKEND_WEBSOCKET_URL ||
  'wss://dev.ws.api.talkdoc.com?token=';

export const projectEnv =
  process.env.REACT_APP_PROJECT_ENV || 'local';

export const reCaptchaKey = process.env.REACT_APP_RECAPTCHAKEY || '6LeriJwcAAAAAPLZelYS5R3Gdu4cH9cM3w0OyQxg';

export const googleClientId = process.env.GOOGLE_CLIENT_ID || '44082712844-spmt719vgg2udfjh3c2rvbh25d5g6nbd.apps.googleusercontent.com';