const namespace = 'talkdoc/messagecenter';

export const GETCLINICIANPATIENTPAIRS = `${namespace}/getclinicianpatientpairs`;
export const GETCLIENTDETAILARRAY = `${namespace}/getclientdetailarray`;
export const GETCHATROOMTOKENPAIRS = `${namespace}/getchatroomtokenpairs`;
export const GETCHATDETAILS = `${namespace}/getchatdetails`;
export const SETSELECTEDCHATUSERID = `${namespace}/setselectedchatuserid`;
export const SETSELECTEDCHATTAB = `${namespace}/setselectedchattab`;
export const MARKCHANNELUNREAD = `${namespace}/markchannelunread`;
export const RESETCHATDETAILSSTATEFIELD = `${namespace}/resetchatdetailsstatefield`;
