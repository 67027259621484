const namespace = 'talkdoc/calendar';

export const GETAPPOINTMENTSLIST = `${namespace}/getappointmentslist`;
export const UPDATEAPPOINTMENT = `${namespace}/updateappointment`;
export const GETAPPOINTMENTBYID = `${namespace}/getappointmentbyid`;
export const DECLINEAPPOINTMENT = `${namespace}/declineappointment`;
export const GETNEXTAPPOINTMENTDETAIL = `${namespace}/getnextappointmentdetail`;
export const CREATEAPPOINTMENT = `${namespace}/createappointment`;
export const SYNCAPPOINTMENTS = `${namespace}/syncappointments`;

export const GETCLINICIANLIST = `${namespace}/getclinicianlist`;
export const GETAVAILABILITYLISTBYID = `${namespace}/getavailabilitylistbyid`;
export const GETCLINICIANTIMESLOTS = `${namespace}/getcliniciantimeslots`;
export const GETAPPOINTMENTDURATIONOPTIONS = `${namespace}/getappointmentdurationoptions`;
