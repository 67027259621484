import { handleActions, createAction } from 'redux-actions';
import { defineLoopActions, requestLoopHandlers } from 'libs/state';
import { REQUEST_STATUS } from 'config/constants';

import {
  REGISTERCLINICIAN,
  LOGINCLINICIAN,
  LOGOUTCLINICIAN,
  VERIFYACTIVATIONTOKEN,
  SENDMFACODE,
  VERIFYMFACODE,
  SENDMFACODECOUNT,
  VERIFYMFACODECOUNT,
  REQUESTPASSWORDRESET,
  VERIFYRESETPASSWORDTOKEN,
  RESETPASSWORD,
  REQUESTPATIENTACTIVATIONLINK,
} from './actionTypes';

const initialState = {
  verify_token_payload: {},
  verify_resetpasswordtoken_payload: {},
  reset_password_payload: {},
  register_payload: {},
  login_payload: {},
  clinician_profile: {},
  token: undefined,
  isLoggedIn: false,
  verify_token_state: REQUEST_STATUS.INITIAL,
  register_state: REQUEST_STATUS.INITIAL,
  login_state: REQUEST_STATUS.INITIAL,
  send_mfa_code_state: REQUEST_STATUS.INITIAL,
  verify_mfa_code_state: REQUEST_STATUS.INITIAL,
  request_passwordreset_state: REQUEST_STATUS.INITIAL,
  verify_resetpasswordtoken_state: REQUEST_STATUS.INITIAL,
  reset_password_state: REQUEST_STATUS.INITIAL,
  send_mfa_code_count: 5,
  verify_mfa_code_count: 5,
  patient_activation_link_request_state: REQUEST_STATUS.INITIAL,
};

export const {
  start: verifyResetPasswordToken,
  success: verifyResetPasswordTokenSuccess,
  fail: verifyResetPasswordTokenFail,
} = defineLoopActions(VERIFYRESETPASSWORDTOKEN);

export const {
  start: resetPassword,
  success: resetPasswordSuccess,
  fail: resetPasswordFail,
  reset: resetPasswordReset,
} = defineLoopActions(RESETPASSWORD);

export const {
  start: verifyToken,
  success: verifyTokenSuccess,
  fail: verifyTokenFail,
} = defineLoopActions(VERIFYACTIVATIONTOKEN);

export const {
  start: registerClinician,
  success: registerClinicianSuccess,
  fail: registerClinicianFail,
  reset: registerClinicianReset,
} = defineLoopActions(REGISTERCLINICIAN);

export const {
  start: loginClinician,
  success: loginClinicianSuccess,
  fail: loginClinicianFail,
  reset: loginClinicianReset,
} = defineLoopActions(LOGINCLINICIAN);

export const {
  start: logoutClinician,
  success: logoutClinicianSuccess,
  fail: logoutClinicianFail,
} = defineLoopActions(LOGOUTCLINICIAN);

export const {
  start: requestPasswordReset,
  success: requestPasswordResetSuccess,
  fail: requestPasswordResetFail,
} = defineLoopActions(REQUESTPASSWORDRESET);

export const {
  start: sendMfaCode,
  success: sendMfaCodeSuccess,
  fail: sendMfaCodeFail,
} = defineLoopActions(SENDMFACODE);

export const {
  start: verifyMfaCode,
  success: verifyMfaCodeSuccess,
  fail: verifyMfaCodeFail,
} = defineLoopActions(VERIFYMFACODE);

export const {
  start: requestPatientActivationLink,
  success: requestPatientActivationLinkSuccess,
  fail: requestPatientActivationLinkFail,
} = defineLoopActions(REQUESTPATIENTACTIVATIONLINK);

export const setSendMfaCodeCount = createAction(SENDMFACODECOUNT);
export const setVerifyMfaCodeCount = createAction(VERIFYMFACODECOUNT);

export const authReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: RESETPASSWORD,
      onSuccess: (state, payload) => {
        return {
          ...state,
          reset_password_payload: payload,
          reset_password_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'reset_password_state',
    }),

    ...requestLoopHandlers({
      action: VERIFYACTIVATIONTOKEN,
      onSuccess: (state, payload) => {
        return {
          ...state,
          verify_token_payload: payload,
          verify_token_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'verify_token_state',
    }),

    ...requestLoopHandlers({
      action: VERIFYRESETPASSWORDTOKEN,
      onSuccess: (state, payload) => {
        return {
          ...state,
          verify_resetpasswordtoken_payload: payload,
          non_field_errors: [],
          verify_resetpasswordtoken_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'verify_resetpasswordtoken_state',
    }),

    ...requestLoopHandlers({
      action: REGISTERCLINICIAN,
      onSuccess: (state, payload) => {
        return {
          ...state,
          register_payload: payload,
          register_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onEnd: () => {
        return {
          ...initialState,
        };
      },
      stateField: 'register_state',
    }),

    ...requestLoopHandlers({
      action: LOGINCLINICIAN,
      onSuccess: (state, payload) => {
        localStorage.setItem('expiry', payload.expiry);
        localStorage.setItem('tokenExpired', false);
        return {
          ...state,
          token: payload.token,
          login_state: REQUEST_STATUS.SUCCESS,
          clinician_profile: payload.user,
          send_mfa_code_count: 5,
          verify_mfa_code_count: 5,
        };
      },
      stateField: 'login_state',
    }),

    ...requestLoopHandlers({
      action: REQUESTPASSWORDRESET,
      onSuccess: (state, payload) => {
        return {
          ...state,
          request_passwordreset_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: (state) => {
        return {
          ...state,
          request_passwordreset_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'request_passwordreset_state',
    }),

    ...requestLoopHandlers({
      action: LOGOUTCLINICIAN,
      onSuccess: (state, payload) => {
        localStorage.removeItem('persist:root');
        localStorage.removeItem('expiry');
        return {
          register_state: REQUEST_STATUS.INITIAL,
          login_state: REQUEST_STATUS.INITIAL,
          register_payload: {},
          login_payload: {},
          token: undefined,
          isLoggedIn: false,
          send_mfa_code_count: 5,
          verify_mfa_code_count: 5,
        };
      },
    }),

    ...requestLoopHandlers({
      action: SENDMFACODE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          send_mfa_code_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'send_mfa_code_state',
    }),

    ...requestLoopHandlers({
      action: VERIFYMFACODE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          verify_mfa_code_state: REQUEST_STATUS.SUCCESS,
        };
      },
      stateField: 'verify_mfa_code_state',
    }),
    ...requestLoopHandlers({
      action: REQUESTPATIENTACTIVATIONLINK,
      onSuccess: (state, payload) => {
        return {
          ...state,
          patient_activation_link_request_state: REQUEST_STATUS.SUCCESS,
        };
      },
      onFail: (state) => {
        return {
          ...state,
          patient_activation_link_request_state: REQUEST_STATUS.FAIL,
        };
      },
      stateField: 'patient_activation_link_request_state'
    }),

    [SENDMFACODECOUNT]: (state, { payload }) => {
      console.log('Set send code count: ', payload);
      return {
        ...state,
        send_mfa_code_count: payload,
      };
    },

    [VERIFYMFACODECOUNT]: (state, { payload }) => {
      console.log('Set verify code count: ', payload);
      return {
        ...state,
        verify_mfa_code_count: payload,
      };
    },
  },
  initialState
);
