import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';

const AuthRoute = ({ component: Component, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={componentProps => {
        if (routeProps.private) {
          if (!routeProps.loggedIn) {
            return <Redirect to="/login" />;
          } else if (
            !routeProps.isMfaVerified &&
            routeProps.name !== 'MfaLogin'
          ) {
            return <Redirect to="/confirmlogin" />;
          } else {
            return <Component {...componentProps} />;
          }
        } else {
          if (routeProps.loggedIn && routeProps.name === 'Login') {
            if (!routeProps.isMfaVerified)
              return <Redirect to="/confirmlogin" />;
            else return <Redirect to="/dashboard/calendar" />;
          } else {
            return <Component {...componentProps} />;
          }
        }
      }}
    />
  );
};

export default AuthRoute;
