import { takeLatest, call, put } from 'redux-saga/effects';
import { getToken } from 'libs/token';

import {
  getAppointmentsList,
  updateAppointment,
  declineAppointment,
  getNextAppointmentDetail,
  createAppointment,
  getClinicianList,
  getAvailabilityListById,
  getClinicianTimeSlots,
  getAppointmentById,
  getAppointmentDurationOptions
} from 'api/methods/calendar';

import { getAvailabilityList } from 'api/methods/onboarding';

import {
  UPDATEAPPOINTMENT,
  DECLINEAPPOINTMENT,
  GETAPPOINTMENTSLIST,
  GETNEXTAPPOINTMENTDETAIL,
  CREATEAPPOINTMENT,
  GETCLINICIANLIST,
  GETAVAILABILITYLISTBYID,
  GETCLINICIANTIMESLOTS,
  GETAPPOINTMENTBYID,
  GETAPPOINTMENTDURATIONOPTIONS,
} from './actionTypes';

import {
  getAppointmentsListSuccess,
  getAppointmentsListFail,
  updateAppointmentSuccess,
  updateAppointmentFail,
  getAppointmentByIdSuccess,
  getAppointmentByIdFail,
  declineAppointmentSuccess,
  declineAppointmentFail,
  getNextAppointmentDetailSuccess,
  getNextAppointmentDetailFail,
  createAppointmentSuccess,
  createAppointmentFail,
  getClinicianListSuccess,
  getClinicianListFail,
  getAvailabilityListByIdSuccess,
  getAvailabilityListByIdFail,
  getClinicianTimeSlotsSuccess,
  getClinicianTimeSlotsFail,
  getAppointmentDurationOptionsSuccess,
  getAppointmentDurationOptionsFail
} from './reducer';

import {
  getAvailabilityListSuccess,
  getAvailabilityListFail,
} from 'views/onboarding/reducer';

function* get_appointments_list(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getAppointmentsList,
    action.payload.types,
    userToken
  );
  if (response.status === 200) {
    yield put(getAppointmentsListSuccess(response.data));
  } else {
    yield put(getAppointmentsListFail(response.data));
  }
}

function* update_appointment(action) {
  const userToken = `Token ${getToken()}`;
  const appointment_id = action.payload.id;
  delete action.payload['id'];
  const response = yield call(
    updateAppointment,
    appointment_id,
    action.payload,
    userToken
  );
  if (response.status === 200) {
    yield put(updateAppointmentSuccess(response.data));
  } else {
    yield put(updateAppointmentFail(response.data));
  }
}

function* decline_appointment(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    declineAppointment,
    action.payload.id,
    action.payload.payload,
    userToken
  );
  if (response.status === 200) {
    const abltResponse = yield call(getAvailabilityList, '', userToken);
    if (abltResponse.status === 200) {
      yield put(getAvailabilityListSuccess(abltResponse.data));
    } else {
      yield put(getAvailabilityListFail(abltResponse.data));
    }
    yield put(declineAppointmentSuccess(response.data));
  } else {
    yield put(declineAppointmentFail(response.data));
  }
}

function* get_appointment_by_id(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getAppointmentById,
    action.payload.id,
    userToken
  );
  if (response.status === 200) {
    yield put(getAppointmentByIdSuccess(response.data));
  } else {
    yield put(getAppointmentByIdFail(response.data));
  }
}

function* get_next_appointment_detail(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getNextAppointmentDetail, userToken);
  if (response.status === 200) {
    yield put(getNextAppointmentDetailSuccess(response.data));
  } else {
    yield put(getNextAppointmentDetailFail(response.data));
  }
}

function* create_appointment(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(createAppointment, action.payload, userToken);
  if (response.status === 201) {
    const abltResponse = yield call(getAvailabilityList, '', userToken);
    if (abltResponse.status === 200) {
      yield put(getAvailabilityListSuccess(abltResponse.data));
    } else {
      yield put(getAvailabilityListFail(abltResponse.data));
    }
    yield put(createAppointmentSuccess(response.data));
  } else {
    yield put(createAppointmentFail(response.data));
  }
}

function* get_clinician_list(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getClinicianList, userToken);
  if (response.status === 200) {
    yield put(getClinicianListSuccess(response.data));
  } else {
    yield put(getClinicianListFail(response.data));
  }
}

function* get_availability_listbyid(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(
    getAvailabilityListById,
    action.payload.filter,
    userToken
  );
  if (response.status === 200) {
    yield put(getAvailabilityListByIdSuccess(response.data));
  } else {
    yield put(getAvailabilityListByIdFail(response.data));
  }
}

function* get_cliniciantime_slots(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getClinicianTimeSlots, action.payload, userToken);
  if (response.status === 200) {
    yield put(getClinicianTimeSlotsSuccess(response.data.slots));
  } else {
    yield put(getClinicianTimeSlotsFail(response.data));
  }
}

function* get_appointment_duration_options(action) {
  const userToken = `Token ${getToken()}`;
  const response = yield call(getAppointmentDurationOptions, action.payload, userToken);
  if (response.status === 200) {
    yield put(getAppointmentDurationOptionsSuccess(response.data));
  } else {
    yield put(getAppointmentDurationOptionsFail(response.data));
  }
}

export const saga = function* () {
  yield takeLatest(GETAPPOINTMENTSLIST, get_appointments_list);
  yield takeLatest(UPDATEAPPOINTMENT, update_appointment);
  yield takeLatest(DECLINEAPPOINTMENT, decline_appointment);
  yield takeLatest(GETAPPOINTMENTBYID, get_appointment_by_id);
  yield takeLatest(GETNEXTAPPOINTMENTDETAIL, get_next_appointment_detail);
  yield takeLatest(CREATEAPPOINTMENT, create_appointment);
  yield takeLatest(GETCLINICIANLIST, get_clinician_list);
  yield takeLatest(GETAVAILABILITYLISTBYID, get_availability_listbyid);
  yield takeLatest(GETCLINICIANTIMESLOTS, get_cliniciantime_slots);
  yield takeLatest(GETAPPOINTMENTDURATIONOPTIONS, get_appointment_duration_options);
};
